import React from 'react';
import "./VideoPlayer.scss";
import {Portal} from "react-portal";
import {uploads_url} from "../../helpers/request/connect";
import {IoMdClose} from "react-icons/io";


const VideoPlayer = (props: {video: any, onClose: ()=>void}) => {
    const {video, onClose} = props;

    const content = <div className={"video-player"}>
        <div className={"video-player-close"} onClick={onClose}><IoMdClose fill={"#ffffff"} size={40}/></div>
       {video.link ? <iframe src={"https://www.youtube.com/embed/" + video.link}
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen></iframe> : <video onContextMenu={(e)=>e.preventDefault()} disablePictureInPicture controlsList={"nodownload"} autoPlay={true} controls={true} src={uploads_url + "/videos/" + video.file}/>}
</div>;

    return (<Portal node={document && document.getElementById('video-player-content')}>
        {content}
    </Portal>);
};

export default VideoPlayer;
