import React, {useEffect, useMemo, useState} from 'react';
import {conn} from "../helpers/request/connect";
import Div from "../components/Div/Div";
import Preloader from "./components/Preloader/Preloader";
import Space from "../components/Space/Space";
import Text from "../components/Text/Text";
import Button from "./components/Button/Button";
import ListItem from "../components/ListItem/ListItem";
import Dialog from "./components/Dialog/Dialog";
import Input from "../components/Input/Input";
import LangTabs from "./components/LangTabs/LangTabs";
import {CiCirclePlus} from "react-icons/ci";
import ProductEditPage from "./ProductEditPage";
import {useNavigate, useParams} from "react-router";
import {Select} from "./components/Select/Select";

const ProductsPage = () => {

    const params = useParams();
    const navigate = useNavigate();

    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [editContent, setEditContent] = useState(null);
    const [products, setProducts] = useState([]);


    const cat_id = useMemo(()=>{
        return params.p2;
    }, [params]);


    useEffect(() => {
        conn("products/getCategories").then((res: any) => {
            if (res.response) setCategories([...res.response, {title_hy: "None", id: "", sort: 0}]?.sort((a,b)=>a.sort - b.sort));
            setLoading(false);
        });
    }, []);


    useEffect(()=>{
        setLoading(true);
        conn("products/getProducts", {category_id: cat_id}).then((res:any)=>{
           if(res.response) setProducts(res.response.sort((a,b)=>a.sort - b.sort));
           setLoading(false);
        });
    }, [cat_id]);


    return (<Div column>
            <Preloader active={loading}/>
            <Space/>
            <Text size={"lm"} color={"main"}>Products</Text>
            <Space/>
            <Button onClick={() => {
                setEditContent("new");
            }} stretch={"fixed"} title={"Add new"} color={"green"}/>
            <Space/>
            <Text color={"grey-light"} size={"xsm"}>Filter by category</Text>
            <Space size={"xsm"}/>
            <Select selected={Number(cat_id)} onChange={(e) => navigate("/admin/products/"+ e.id)} search={true}
                    options={[...categories]?.map(dis => {
                        return {title: dis.title_hy, id: dis.id}
                    })}/>
            <Space/>
            <Div column>
                {products.map(item => {
                    return <ListItem
                        key={"slide-" + item.id}
                        hover
                        onClick={() => {
                            setEditContent(item.id);
                        }}
                        title={<Text>{item.sort} {item.title_hy}
                        </Text>}/>
                })}
            </Div>



            {/*///////////////////// edit /////////////////////*/}

            {/*///////////////////// product edit /////////////////////*/}

            {editContent && <ProductEditPage cat_id={cat_id} id={editContent} onClose={()=>setEditContent(null)}/>}

            {/*///////////////////// remove /////////////////////*/}

        </Div>
    );
};

export default ProductsPage;