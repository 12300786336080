import React from 'react';
import Div from "../Div/Div";
import Text from "../Text/Text";
import "./PlusButton.scss";
const PlusButton = (props: {title: string, onCLick?: ()=>void, state?: boolean, loading?: boolean}) => {
    const {title, onCLick, state = true, loading} = props;

    return (
        <Div alignH={"center"} width={"none"} onClick={!loading? onCLick : undefined} gap={6}>
            {loading ? <>
                <div className="loader"></div></>:(state ?<svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M30.5859 21.0938H23.9062V14.4141C23.9062 14.2207 23.748 14.0625 23.5547 14.0625H21.4453C21.252 14.0625 21.0938 14.2207 21.0938 14.4141V21.0938H14.4141C14.2207 21.0938 14.0625 21.252 14.0625 21.4453V23.5547C14.0625 23.748 14.2207 23.9062 14.4141 23.9062H21.0938V30.5859C21.0938 30.7793 21.252 30.9375 21.4453 30.9375H23.5547C23.748 30.9375 23.9062 30.7793 23.9062 30.5859V23.9062H30.5859C30.7793 23.9062 30.9375 23.748 30.9375 23.5547V21.4453C30.9375 21.252 30.7793 21.0938 30.5859 21.0938Z"
                    fill="#009CBF"/>
                <path
                    d="M22.5 2.8125C11.6279 2.8125 2.8125 11.6279 2.8125 22.5C2.8125 33.3721 11.6279 42.1875 22.5 42.1875C33.3721 42.1875 42.1875 33.3721 42.1875 22.5C42.1875 11.6279 33.3721 2.8125 22.5 2.8125ZM22.5 38.8477C13.4736 38.8477 6.15234 31.5264 6.15234 22.5C6.15234 13.4736 13.4736 6.15234 22.5 6.15234C31.5264 6.15234 38.8477 13.4736 38.8477 22.5C38.8477 31.5264 31.5264 38.8477 22.5 38.8477Z"
                    fill="#009CBF"/>
            </svg> : <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M30.5859 21.0938H14.4141C14.2207 21.0938 14.0625 21.252 14.0625 21.4453V23.5547C14.0625 23.748 14.2207 23.9062 14.4141 23.9062H30.5859C30.7793 23.9062 30.9375 23.748 30.9375 23.5547V21.4453C30.9375 21.252 30.7793 21.0938 30.5859 21.0938Z" fill="#009CBF"/>
                <path d="M22.5 2.8125C11.6279 2.8125 2.8125 11.6279 2.8125 22.5C2.8125 33.3721 11.6279 42.1875 22.5 42.1875C33.3721 42.1875 42.1875 33.3721 42.1875 22.5C42.1875 11.6279 33.3721 2.8125 22.5 2.8125ZM22.5 38.8477C13.4736 38.8477 6.15234 31.5264 6.15234 22.5C6.15234 13.4736 13.4736 6.15234 22.5 6.15234C31.5264 6.15234 38.8477 13.4736 38.8477 22.5C38.8477 31.5264 31.5264 38.8477 22.5 38.8477Z" fill="#009CBF"/>
            </svg>)}
            {title && !loading ? <Text size={"md"} color={"main"}>{title}</Text> : null}

        </Div>
    );
};

export default PlusButton;
