import React, {useEffect, useState} from 'react';
import "./../PageStyles.scss";
import Div from "../../components/Div/Div";
import Space from "../../components/Space/Space";
import Text from "../../components/Text/Text";
import PlusButton from "../../components/PlusButton/PlusButton";
import {conn} from "../../helpers/request/connect";
import FilterLine from "../../components/FilterLine/FilterLine";
import {lang, lg} from "../../helpers/lang/lang";
import {langChoose} from "../../helpers";
import Preloader from "../../admin/components/Preloader/Preloader";
import Table from "../../components/Table/Table";

const PublicationsPage = () => {

    const [loading, setLoading] = useState(true);
    const [catalogs, setCatalogs] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [filter, setFiler] = useState<any>({});
    const [data, setData] = useState<any>({});
    const [pageLoading, setPageLoading] = useState(false);

    useEffect(()=>{
        conn("web/getFilterItems", {journals: true, years: true}).then((res:any)=>setData(res));
    }, []);

    useEffect(()=>{
        if(page){
            conn("web/getPublications", {...(filter ?? {}), page: page, limit: 12}).then((res:any)=>{
                if(res.response) setCatalogs(page === 1? res.response: prev=>[...prev, ...res.response]);
                if(res.count || res.count === 0) setCount(res.count);
                setLoading(false);
                setPageLoading(false);
            });
        }
    }, [page, filter]);

    return (
        <Div column>
            <Preloader active={loading}/>
            <Div className={"width"} column>
                <Space size={"xlg"}/>
                <Text size={"lg"} color={"main"}>{lang["publications"]}</Text>
                <Space size={"sm"}/>
                <Text uppercase size={"md"} color={"grey-light"}>{lang["all-our-publications"]}</Text>
            </Div>
            <Space/>
            <Div className={"filter-content width"} column>
                <Space/>
                <FilterLine filters={[
                    {id: "year", title: lang["year"], items: data?.years?.map(item=>{return {id: item.year, title: item.year}})},
                    {id: "journal", title: lang["journals"], items: data?.journals?.map(item=>{return {id: item.id, title: item.title}})},
                    {id: "topics", title: lang["topics"], items: data?.topics?.map(item=>{return {id: item.id, title: langChoose(item, "title_", lg)}})},
                    {id: "discipline_id", title: lang["disciplines"], items: data?.disciplines?.map(item=>{return {id: item.id, title: langChoose(item, "title_", lg)}})},
                    {id: "language", title: lang["languages"], items: data?.languages?.map(item=>{return {id: item.id, title: item.title}})},

                ]} onChange={(e)=>{
                    setCatalogs([]);
                    setPageLoading(true);
                    setFiler(e);
                    setPage(0);
                    setTimeout(()=>setPage(1), 100);
                }}/>

            <Space/>
            <Space line={true}/>
                <Space/>
            <Table
            headers={[{title: lang["year"], id: "year", size: "s-column"}, {title: lang["journals"], id: "journal", size: "m-column"}, {title:lang["title"], id: "title", size: "full-column"}]}
            list={catalogs?.map(pub=> {
                return {title: langChoose(pub, "title_", lg), year: pub.year, journal: pub.journal, link: pub.link}
            })}
            />
                <Space size={"md"}/>


            <Space/>
            {count > catalogs?.length || pageLoading ? <Div alignV={"center"}>
                <PlusButton loading={pageLoading} onCLick={()=> {
                    setPage(prev => prev + 1);
                    setPageLoading(true);
                }} title={"See more"}/>
            </Div>: null}
            </Div>
            <Space/>
        </Div>
    );
};

export default PublicationsPage;
