import React, {useEffect, useState} from 'react';
import "./DisciplinePage.scss";
import Div from "../../components/Div/Div";
import MainSlider from "../../components/MainSlider/MainSlider";
import Space from "../../components/Space/Space";
import PageTitle from "../../components/PageTitle/PageTitle";
import {lang, lg} from "../../helpers/lang/lang";
import Text from "../../components/Text/Text";
import GradientBG from "../../components/GradientBG/GradientBG";
import {Link} from "react-router-dom";
import ProductItem from "../../components/ProductItem/ProductItem";
import PlusButton from "../../components/PlusButton/PlusButton";
import SimpleScroller from "../../components/SimpleScroller/SimpleScroller";
import {BsPlayCircle} from "react-icons/bs";
import Preloader from "../../admin/components/Preloader/Preloader";
import {conn, uploads_url} from "../../helpers/request/connect";
import {useParams} from "react-router";
import {getImage, getVideoCover, langChoose} from "../../helpers";
import VideoPlayer from "../../components/VideoPlayer/VideoPlayer";
import {Select} from "../../admin/components/Select/Select";
import EditorTextViewer from "../../components/EditorTextViewer/EditorTextViewer";


const ProductPage = () => {

    const params = useParams();
    const [loading, setLoading] = useState(true);
    const [product, setProduct] = useState<any>({});
    const [videos, setVideos] = useState([]);
    const [selectedVideo, setSelectedVideo] = useState(null);

    useEffect(()=>{
        if(params.p2){
            setLoading(true);
            conn("web/getProduct", {id: params.p2}).then((res:any)=>{
                if(res.response) setProduct(res.response);
                setLoading(false)
            });
            conn("web/getVideos", {product_id: params.p2, limit: 999}).then(((res:any)=>{
                setVideos(res.response);
            }));
        }else window.location.href = "/";
    }, [params?.p2])


    return ( <Div column className={"discipline"}>
            <Preloader active={loading}/>
            {product.image ? <MainSlider
                slides={[{text1: langChoose(product, "title_", lg), image: getImage(product?.image),}]}/> : null}
            <Div className={"width"} column>
                <Space size={"xlg"}/>
                <Text size={"lm"}>{langChoose(product, "title_", lg)}</Text>
                <Link to={"/"+lg+"/product-category/" + product.category_id}><Text color={"main"} size={"xmd"}>{langChoose(product, "cat_title_", lg)}</Text></Link>
                <Space/>
                <EditorTextViewer text={langChoose(product, "text_", lg)}/>
                {product.file ? <>
                    <Space/>
                    <Link target={"_blank"} to={uploads_url + "/files/" + product.file}>
                    <Text size={"md"} color={"main"}>{lang["see-more"]}</Text>
                </Link></>: null}
                <Space size={"md"}/>
            </Div>
            {videos?.length ? <Div column className={"discipline-video-scroller"}>
                <Div column className={"width"}>
                    <PageTitle>{lang["videos"]}</PageTitle>
                    <Space line/>
                </Div>
                <Space/>
                <SimpleScroller items={videos?.map(video => {
                    return <Div onClick={() => setSelectedVideo(video)} key={"media-scroller" + video.id}
                                className={"video-scroller-item"}>
                        <img src={getVideoCover(video)}/>
                        <BsPlayCircle/>
                    </Div>
                })}/>
                <Space/>
            </Div> : null}
            {selectedVideo? <VideoPlayer video={selectedVideo} onClose={()=>setSelectedVideo(null)}/> : null}
        </Div>
    );
};

export default ProductPage;
