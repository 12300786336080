import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import MainPage from "./MainPage";
import Main from "../admin/Main";

const MainRouter = () => {

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/admin/:p1?/:p2?/:p3?" element={<Main/>}/>
                <Route path="/:l?/:p1?/:p2?/:p3?" element={<MainPage/>}/>
            </Routes>
        </BrowserRouter>
    );
};

export default MainRouter;
