import React, {useEffect, useState} from 'react';
import Div from "../../components/Div/Div";
import Space from "../../components/Space/Space";
import Text from "../../components/Text/Text";
import PlusButton from "../../components/PlusButton/PlusButton";
import {conn, uploads_url} from "../../helpers/request/connect";
import GradientBG from "../../components/GradientBG/GradientBG";
import FilterLine from "../../components/FilterLine/FilterLine";
import {lang, lg} from "../../helpers/lang/lang";
import {catalogTypes, getImage, langChoose} from "../../helpers";
import {Link} from "react-router-dom";
import {RiDownloadCloudFill} from "react-icons/ri";
import Preloader from "../../admin/components/Preloader/Preloader";

const CatalogsPage = () => {

    const [loading, setLoading] = useState(true);
    const [catalogs, setCatalogs] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [filter, setFiler] = useState<any>({});
    const [data, setData] = useState<any>({});
    const [pageLoading, setPageLoading] = useState(false);

    useEffect(()=>{
        conn("web/getFilterItems").then((res:any)=>setData(res));
    }, []);

    useEffect(()=>{
        if(page){
            conn("web/getCatalogs", {...(filter ?? {}), page: page, limit: 12}).then((res:any)=>{
                if(res.response) setCatalogs(page === 1? res.response: prev=>[...prev, ...res.response]);
                if(res.count || res.count === 0) setCount(res.count);
                setLoading(false);
                setPageLoading(false);
            });
        }
    }, [page, filter]);

    return (
        <Div column>
            <Preloader active={loading}/>
            <Div className={"width"} column>
                <Space size={"xlg"}/>
                <Text size={"lg"} color={"main"}>{lang["brochures-catalogs-flyers"]}</Text>
                <Space size={"xsm"}/>
                <Text uppercase size={"md"} color={"grey-light"}>{lang["all-catalogs"]}</Text>
            </Div>
            <Space/>
            <GradientBG>
                <FilterLine filters={[
                    {id: "discipline_id", title: lang["disciplines"], items: data?.disciplines?.map(item=>{return {id: item.id, title: langChoose(item, "title_", lg)}})},
                    {id: "topics", title: lang["topics"], items: data?.topics?.map(item=>{return {id: item.id, title: langChoose(item, "title_", lg)}})},
                    {id: "type", title: lang["media"], items: catalogTypes?.map(item=>{return {id: item, title: lang[item]}})},
                    {id: "language", title: lang["languages"], items: data?.languages?.map(item=>{return {id: item.id, title: item.title}})},

                ]} onChange={(e)=>{
                    setCatalogs([]);
                    setPageLoading(true);
                    setFiler(e);
                    setPage(0);
                    setTimeout(()=>setPage(1), 100);
                }}/>
            </GradientBG>
            <Space/>
            <Div className={"media-library-page-content width"}>{catalogs.map(item=>{
                return <Link target={"_blank"} key={"item-id-" + item.id} to={uploads_url + "/files/" + item.file}><Div className={"media-library-item"}>
                    <img src={getImage(item.image)}/>
                    <Div className={"media-library-item-play"}><RiDownloadCloudFill/></Div>
                    <Div className={"media-library-item-title"}><Text linesCount={6} size={"md"} align={"center"} color={"light"}>{langChoose(item, "title_", lg)}</Text></Div>
                </Div></Link>
            })}</Div>
            <Space/>
            {count > catalogs?.length ? <Div alignV={"center"}>
                <PlusButton loading={pageLoading} onCLick={()=> {
                    setPage(prev => prev + 1);
                    setPageLoading(true);
                }} title={lang["see-more"]}/>
            </Div>: null}
            <Space/>
        </Div>
    );
};

export default CatalogsPage;
