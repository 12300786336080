import React, {useEffect, useMemo, useState} from 'react';
import Div from "../Div/Div";
import "./FilterLine.scss";
import ContextMenu from "../ContextMenu/ContextMenu";
import Text from "../Text/Text";
import classNames from "classnames";
import ListItem from "../ListItem/ListItem";
import Input from "../Input/Input";
import Space from "../Space/Space";
import {conditionSearch} from "../../helpers/search";
import {FiSearch} from "react-icons/fi";
import {lang} from "../../helpers/lang/lang";

const downIcon = <svg width="15" height="8" viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.5 8L14.5 1.07692L13.3333 -8.41256e-08L7.5 5.69231L1.66667 4.25841e-07L0.5 1.07692L7.5 8Z"/>
</svg>;

const FilterLine = (props: { onChange: (e: any) => void, filters: any[] }) => {
    const {onChange, filters} = props;


    const [val, setVal] = useState<any>({})


    return (
        <Div className={"filter-line"}>
            {filters.map(filter => {
                return <FilterButton key={"filter-button-" + filter.id} value={val[filter.id]} filter={filter} onChange={(e)=>{
                    onChange({...val, [`${filter.id}`]: e});

                    setVal(prev=>{
                        return {...prev, [`${filter.id}`]: e}
                    });
                }}/>
            })}
        </Div>
    );
};

const FilterButton = (props: { onChange, filter, value }) => {
    const {onChange, filter, value = ""} = props;
    const [context, setContext] = useState(null);
    const classes = classNames("filter-button", {selected: context});
    const [search, setSearch] = useState("");

    const items = useMemo(()=>{
        return search? filter?.items.filter(x => conditionSearch(x.title, search)) : filter?.items;
    }, [filter, search])

    return <>
        <Div className={classes} onClick={(e) => setContext(e.target.closest(".filter-button"))}>
            <Text size={"md"} color={"light"}>{value? filter.items.find(x => x.id === value)?.title : filter.title}</Text> {downIcon}
        </Div>
        {context ? <ContextMenu
            noScrollTop={filter?.items?.length > 5? <Div>
                <Space vertical={false} size={"xsm"}/>
                <Input rightIcon={<FiSearch stroke={"silver"} />} autofocus value={search} onChange={setSearch} clearButton={true} onClear={()=>setSearch("")} placeholder={lang["search"]}/>
                <Space vertical={false} size={"xsm"}/>
            </Div>: undefined}
            size={"sm"}
            alignTo={context}
            onClose={()=> {
                setContext(null);
                setSearch("");
            }}
            content={[{title: lang["all"], id: ""}, ...items].map(item=>{
                return <ListItem selected={value === item.id} onClick={()=> {
                    setContext(null);
                    setSearch("");
                    onChange(item.id);
                }} key={"filter-list" + item.id} hover={true} title={item.title}/>
            })}
        /> : null}
    </>
}


export default FilterLine;
