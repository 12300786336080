import React, {useEffect, useState} from 'react';
import Div from "../components/Div/Div";
import Text from "../components/Text/Text";
import Space from "../components/Space/Space";
import Button from "./components/Button/Button";
import ListItem from "../components/ListItem/ListItem";
import {conn, uploadFile} from "../helpers/request/connect";
import Dialog from "./components/Dialog/Dialog";
import Uploader from "./components/Uploader/Uploader";
import {getImage} from "../helpers";
import Input from "../components/Input/Input";
import Tabs from "./components/Tabs/Tabs";
import Editor from "./components/Editor/Editor";
import Preloader from "./components/Preloader/Preloader";
import LangTabs from "./components/LangTabs/LangTabs";

const Journals = () => {

    const [items, setItems] = useState([]);
    const [info, setInfo] = useState<any>({});
    const [editContext, setEditContext] = useState(false);
    const [remove, setRemove] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        conn("journals/get").then((res: any) => {
            if (res.response) setItems(res?.response);
            setLoading(false);
        })
    }, [])

    const saveDoc = (data) => {
        setLoading(true);
        conn("journals/save", data).then(() => {
            window.location.reload();
        });
    }


    return (
        <Div column>
            <Preloader active={loading}/>
            <Space/>
            <Text size={"lm"} color={"main"}>Journals</Text>
            <Space/>
            <Button onClick={() => setEditContext(true)} stretch={"fixed"} title={"Add new"} color={"green"}/>
            <Space/>
            <Div column>
                {items.map(item => {
                    return <ListItem
                        key={"slide-" + item.id}
                        hover
                        onClick={() => {
                            setInfo(item);
                            setEditContext(true);
                        }}
                        title={<Div gap={4}>{item.title}
                        </Div>}/>
                })}
            </Div>

            {/*///////////////////// edit /////////////////////*/}

            {editContext && <Dialog
                size={"xsm"}
                title={"Journal"}
                onClose={() => {
                    setInfo({});
                    setEditContext(false);
                }}
            >
                <Input type={"number"} label={"Sort"} placeholder={"Sort"} value={info.sort ?? "0"}
                       onChange={(e) => setInfo(prev => {
                           return {...prev, sort: e}
                       })}/>
                <Space/>
                <Input stretch={"full"} label={"Title"} placeholder={"Title"} value={info.title}
                       onChange={(e) => setInfo(prev => {
                           return {...prev, title: e}
                       })}/>

                <Space/>
                <Div gap={12} alignV={"end"}>
                    <Button stretch={"full"} title={"Cancel"} onClick={() => {
                        setInfo({});
                        setEditContext(false);
                    }} color={"grey"}/>
                    <Button stretch={"full"} title={"Save"} onClick={() => {
                        if (!info.title?.length) {
                            alert("Fill in required fields");
                        } else {
                            saveDoc(info);
                        }
                    }} color={"green"}/>
                    {info.id ? <Button stretch={"full"} title={"Delete"} onClick={() => setRemove(info.id)} color={"red"}/> : null}
                </Div>
            </Dialog>}

            {/*///////////////////// remove /////////////////////*/}

            {remove && <Dialog
                size={"xsm"}
                title={"Delete"}
                onClose={() => setRemove(null)}
                buttons={{
                    right: <Div>
                        <Button title={"Cancel"} color={"transparent"} onClick={() => setRemove(null)}/>
                        <Button title={"Delete"} color={"red"} onClick={() => {
                            conn("journals/remove", {id: info.id}).then(() => window.location.reload())
                        }}/>
                    </Div>
                }}
            >
                <Text>Do you want to delete this?</Text>
            </Dialog>}
        </Div>
    );
};

export default Journals;
