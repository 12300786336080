import React, {ReactElement, useMemo, useState} from 'react';
import Button from "../Button/Button";
import Div from "../../../components/Div/Div";
import Space from "../../../components/Space/Space";

const LangTabs = (props: {tabs: {
    title: string, content: ReactElement | ReactElement[]
    }[]}) => {

    const {tabs} = props;
    const [active, setActive] = useState(0);

    const content = useMemo(()=>{
        return tabs[active].content;
    }, [active, tabs]);

    return (
        <Div column>
            <Div>{tabs.map((v, i)=>{
                return <Button size={"xsm"} key={"tab-" + i} title={v.title} color={i === active? "main":"transparent"} onClick={()=>setActive(i)}/>
            })}</Div>
            <Space size={"sm"}/>
            <Div column={true}>{content}</Div>
        </Div>
    );
};

export default LangTabs;
