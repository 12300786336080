import React, {useEffect, useMemo, useState} from 'react';
import "./ServicesPage.scss";
import Div from "../../components/Div/Div";
import MainSlider from "../../components/MainSlider/MainSlider";
import Space from "../../components/Space/Space";
import {lang, lg} from "../../helpers/lang/lang";
import Text from "../../components/Text/Text";
import GradientBG from "../../components/GradientBG/GradientBG";
import {Link} from "react-router-dom";
import ProductItem from "../../components/ProductItem/ProductItem";
import PlusButton from "../../components/PlusButton/PlusButton";
import {useParams} from "react-router";
import Preloader from "../../admin/components/Preloader/Preloader";
import {conn} from "../../helpers/request/connect";
import {getImage, langChoose} from "../../helpers";

const products = [
    {id: 1, title: "Cardiac, thoracic and vascular Surgery", image: require("./../../images/temp/pr1.jpg")},
    {id: 2, title: "Craniomaxillofacial Surgery", image: require("./../../images/temp/pr2.jpg"),},
    {id: 3, title: "Craniomaxillofacial Surgery", image: require("./../../images/temp/pr2.jpg"),},
    {id: 4, title: "Cardiac, thoracic and vascular Surgery", image: require("./../../images/temp/pr3.jpg")},
    {id: 5, title: "Cardiac, thoracic and vascular Surgery", image: require("./../../images/temp/pr4.jpg")},
    {id: 6, title: "Cardiac, thoracic and vascular Surgery", image: require("./../../images/temp/pr4.jpg")},
]

const ServicesPage = () => {

    const [loading, setLoading] = useState(false);
    const params = useParams();
    const [services, setServices] = useState([]);
    const [showAll, setShowAll] = useState(false);
    const [cover, setCover] = useState(null);

    const service = useMemo(()=>{
        return params?.p2 ? services.find(x => x.id === Number(params.p2)) : {}
    }, [services, params?.p2]);

    const servicesFiltered = useMemo(()=>{
        return showAll ? services?.filter(x => x.id !== params.p2) : services?.filter(x => x.id !== params.p2)?.slice(0, 6);
    }, [showAll, services, params.id]);

    useEffect(()=>{
        setShowAll(false);
        setLoading(true);
            conn("web/getServices").then((res:any)=>{
               if(res.services) setServices(res.services);
               if(res.cover) setCover(res.cover);
              setTimeout(()=> setLoading(false), 200);
            });
    }, [params?.p2])

    return (
        <Div column className={"services-content"}>
            <Preloader active={loading}/>
            {!params.p2 ? <>
                {cover? <MainSlider slides={[{
                    text1: lang["services"],
                    text3: lang["services-slogan"],
                    image: getImage(cover),
                }]}/> : null}

                <Div className={"width"} column>
                    <Space size={"xlg"}/>
                    <Text color={"main"} size={"lg"}>{lang["services"]}</Text>
                    <Space size={"lg"}/>
                    <Text className={"catalogs-sub-text"} color={"grey-light"} size={"lm"} uppercase={true}>{lang["services-text"]}</Text>
                </Div><Space size={"md"}/></> : <Div className={"width"} column>
                <Space/>
                <Div className={"service-item"}>
                    {service?.image ?<Div className={"service-image"}>
                        <img src={getImage(service?.image)}/>
                    </Div>: null}
                    <Div className={"service-text-content"} column>
                        <Text size={"lm"}>{langChoose(service, "title_", lg)}</Text>
                        <Space size={"md"}/>
                        <Text size={"md"}>{langChoose(service, "text_", lg)}</Text>
                    </Div>
                </Div>
                <Space/>
                <Link to={"/" + lg + "/services"}><Text color={"main"} size={"lg"}>{lang["services"]}</Text></Link>
                <Space line/>
                <Space/>
            </Div>}

            <GradientBG><>
                <Div column width={"full"}>
                    <Div wrap={true} className={"product-items-content"}>
                        {servicesFiltered.map(pr => {
                            return <Link to={"/" + lg + "/services/" + pr.id} key={"pr-item" + pr.id}><ProductItem
                                product={pr}/></Link>
                        })}
                    </Div>

                </Div>
                {services?.length > (params.p2 ? 7 : 6) ?<><Space size={"xmd"}/>
                    <Div width={"full"} alignV={"center"}>
                    <PlusButton
                    state={!showAll}
                    title={showAll? lang["see-less"]: lang["see-more"]}
                    onCLick={() =>setShowAll(!showAll)}
                    /></Div></>: null}
            </>
            </GradientBG>

        </Div>
    );
};

export default ServicesPage;
