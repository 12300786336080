import React, {useEffect, useState} from 'react';
import Dialog from "../Dialog/Dialog";
import Button from "../Button/Button";
import "./Editor.scss";
import Uploader from "../Uploader/Uploader";
import {MdDelete} from "react-icons/md";
import {conn, uploadFile, uploads_url} from "../../../helpers/request/connect";
import Preloader from "../Preloader/Preloader";
import Div from "../../../components/Div/Div";
import Text from "../../../components/Text/Text";
import Space from "../../../components/Space/Space";



const MediaPicker = (props: {onClose: ()=>void, onSelect: (e)=>void}) => {
    const {onClose, onSelect} = props;

    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [file, setFile] = useState(null);
    const [remove, setRemove] = useState(null);


    const getItems = ()=>{
        conn("media/get", {folder: "media"}).then((res:any)=>{
            if(res.response){
                setItems(Object.values(res.response));
            }
            setLoading(false);
        })
    }

    useEffect(()=>{
        getItems();
    }, []);


    useEffect(()=>{
        console.log("items", items);
        console.log("remove", remove);
    }, [items, remove])


    return (<Dialog title={"Media uploader"} className={"media-uploader"} onClose={onClose}>
        <Preloader active={loading}/>
        <Div column gap={12}>
            <Div gap={12} alignH={"end"}>
                <Uploader value={file? URL.createObjectURL(file): undefined} disabled={loading} size={"s"} onChange={(e)=>setFile(e)}/>
                {file && <Button loading={loading} size={"xsm"} title={"Upload"} onClick={()=>{
                    setLoading(true);
                    uploadFile(file, "media").then((res:any)=>{
                        if(res.response){
                            setFile(null);
                            getItems();
                        }
                    });
                }}/>}
            </Div>

            <Div gap={12} className={"media-uploader-content"} wrap={true}>
            {items.map((image, i)=>{
                return <div key={"image-item" + i} className={"preview-image"}><img onClick={()=>onSelect(image)}  src={uploads_url + "/media/" + image}/>
                <div className={"media-delete"} onClick={()=>setRemove(image)}><MdDelete/></div>
                </div>
            })}
            </Div>
        </Div>
        {remove && <Dialog size={"xsm"} title={"Delete file"} onClose={()=>setRemove(null)} buttons={{
            right: <Div gap={12}>
            <Button title={"Cancel"} color={"transparent"} onClick={()=>setRemove(null)}/>
            <Button title={"Delete"} color={"red"} onClick={()=>{
                setItems(items.filter(x => x !== remove));
                conn("media/remove", {file: remove, folder: "media"}).then(()=>{
                   getItems();
                });
                setRemove(null);
            }}/>
            </Div>}}>
            <Text align={"center"} weight={"bold"}>Do you want to delete this?</Text>
            <Space size={"xsm"}/>
            <Text color={"red"} align={"center"}>Before deleting, make sure that the file is not being used on the site.</Text>
        </Dialog>}
    </Dialog>);
};

export default MediaPicker;
