import React, {useEffect, useState} from 'react';
import Div from "../components/Div/Div";
import Text from "../components/Text/Text";
import Space from "../components/Space/Space";
import Button from "./components/Button/Button";
import ListItem from "../components/ListItem/ListItem";
import {conn, uploadFile} from "../helpers/request/connect";
import Dialog from "./components/Dialog/Dialog";
import Uploader from "./components/Uploader/Uploader";
import {getImage} from "../helpers";
import Input from "../components/Input/Input";
import Tabs from "./components/Tabs/Tabs";
import Editor from "./components/Editor/Editor";
import Preloader from "./components/Preloader/Preloader";
import LangTabs from "./components/LangTabs/LangTabs";
import ListPicker from "./components/ListPicker/ListPicker";

const Disciplines = () => {

    const [items, setItems] = useState([]);
    const [info, setInfo] = useState<any>({});
    const [editContext, setEditContext] = useState(false);
    const [remove, setRemove] = useState(null);
    const [loading, setLoading] = useState(true);
    const [topics, setTopics] = useState([]);


    useEffect(() => {
        conn("disciplines/get").then((res: any) => {
            if(res.response) setItems(res?.response);
        conn("topics/get").then((top:any)=>{
            if(top.response) setTopics(top.response);
            setLoading(false);
        });
        })
    }, [])

    useEffect(()=>{
        if(info.id) conn("topics/connects", {id: info.id, type: "discipline"}).then((res:any)=>{
            if(res.response) setInfo(prev=>{
                return {...prev, topics: res.response?.map(x=>x.topic_id).join(",")}
            });
        })
    }, [info?.id])

    const saveDoc = (data) => {
        conn("disciplines/save", data).then((res) => {
            window.location.reload();
        });
    }


    return (
        <Div column>
            <Preloader active={loading}/>
            <Space/>
            <Text size={"lm"} color={"main"}>Disciplines</Text>
            <Space/>
            <Button onClick={() => setEditContext(true)} stretch={"fixed"} title={"Add new"} color={"green"}/>
            <Space/>
            <Div column>
                {items.map(item => {
                    return <ListItem
                        key={"slide-" + item.id}
                        hover
                        onClick={() => {
                            setInfo(item);
                            setEditContext(true);
                        }}
                        title={<Div gap={4}>{item.sort} {item.title_hy?.length ? item.title_hy : "Untitled"}
                        </Div>}/>
                })}
            </Div>

            {/*///////////////////// edit /////////////////////*/}

            {editContext && <Dialog
                size={"full"}
                title={"Discipline"}
                onClose={() => {
                    setInfo({});
                    setEditContext(false);
                }}
            >
                <Uploader
                    value={info.image ? (typeof info.image === "string" ? getImage(info.image) : URL.createObjectURL(info.image)) : undefined}
                    onChange={(e) => setInfo(prev => {
                        return {...prev, image: e}
                    })}
                    size={"l"}
                />
                <Space/>
                <Input type={"number"} label={"Sort"} placeholder={"Sort"} value={info.sort ?? "0"}
                       onChange={(e) => setInfo(prev => {
                           return {...prev, sort: e}
                       })}/>
                <Space/>

                <LangTabs tabs={[

                    {
                        title: "Հայերեն",
                        content: <Div column>
                            <Input stretch={"full"} label={"Անվանում"} placeholder={"Անվանում"} value={info.title_hy}
                                   onChange={(e) => setInfo(prev => {
                                       return {...prev, title_hy: e}
                                   })}/>
                            <Space/>
                            <Text color={"grey-light"} size={"xsm"}>Նկարագրություն</Text>
                            <Space size={"xsm"}/>
                            <Editor key={"editor-hy"} toolbar={false} size={"sm"} value={info.text_hy} onChange={(e) => {
                                setInfo(prev => {
                                    return {...prev, text_hy: e}
                                });
                            }}/>
                            <Space/>
                        </Div>
                    },
                    {
                        title: "English",
                        content: <Div column>
                            <Input stretch={"full"} label={"Title"} placeholder={"Title"} value={info.title_en}
                                   onChange={(e) => setInfo(prev => {
                                       return {...prev, title_en: e}
                                   })}/>

                            <Space/>
                            <Text color={"grey-light"} size={"xsm"}>Description</Text>
                            <Space size={"xsm"}/>
                            <Editor key={"editor-en"} toolbar={false} size={"sm"} value={info.text_en} onChange={(e) => setInfo(prev => {
                                return {...prev, text_en: e}
                            })}/>
                            <Space/>
                        </Div>
                    },
                    {
                        title: "Русский",
                        content: <Div column>
                            <Input stretch={"full"} label={"Название"} placeholder={"Название "}
                                   value={info.title_ru} onChange={(e) => setInfo(prev => {
                                return {...prev, title_ru: e}
                            })}/>
                            <Space/>
                            <Text color={"grey-light"} size={"xsm"}>Описание</Text>
                            <Space size={"xsm"}/>
                            <Editor key={"editor-ru"} toolbar={false} size={"sm"} value={info.text_ru} onChange={(e) => setInfo(prev => {
                                return {...prev, text_ru: e}
                            })}/>
                            <Space/></Div>
                    }
                ]}/>
                <Space/>
                <Text color={"grey-light"} size={"xsm"}>Topics</Text>
                <ListPicker items={topics?.map(top=>top)} lang={"hy"} onChange={(e)=>setInfo(prev=>{
                    return {...prev, topics: e}
                })} value={info.topics}/>
                <Space size={"xsm"}/>
                <Space/>
                <Div gap={12} alignV={"end"}>
                    <Button title={"Cancel"} onClick={() => {
                        setInfo({});
                        setEditContext(false);
                    }} color={"transparent"}/>
                    <Button stretch={"fixed"} title={"Save"} onClick={() => {
                        if (!info.image) {
                            alert("Image is required");
                        }else if(!info.title_hy?.length){
                            alert("Fill in required fields");
                        }else {
                            setLoading(true);
                            if (typeof info.image === "string") {
                                saveDoc(info);
                            } else {
                                uploadFile(info.image).then((res: any) => {
                                    if (res.response) saveDoc({...info, image: res.response});
                                    else alert("Image upload error");
                                });
                            }
                        }
                    }} color={"green"}/>
                    {info.id ? <Button title={"Delete"} onClick={() => setRemove(info.id)} color={"red"}/> : null}
                </Div>
            </Dialog>}

            {/*///////////////////// remove /////////////////////*/}

            {remove && <Dialog
                size={"xsm"}
                title={"Delete"}
                onClose={() => setRemove(null)}
                buttons={{
                    right: <Div>
                        <Button title={"Cancel"} color={"transparent"} onClick={() => setRemove(null)}/>
                        <Button title={"Delete"} color={"red"} onClick={() => {
                            conn("disciplines/remove", {id: info.id}).then(() => window.location.reload())
                        }}/>
                    </Div>
                }}
            >
                <Text>Do you want to delete this?</Text>
            </Dialog>}
        </Div>
    );
};

export default Disciplines;
