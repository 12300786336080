import React, {useMemo, useState} from 'react';
import "./ListPicker.scss";

import Button from "../Button/Button";
import {conditionSearch} from "../../../helpers/search";
import Div from "../../../components/Div/Div";
import ListItem from "../../../components/ListItem/ListItem";
import Text from "../../../components/Text/Text";
import Space from "../../../components/Space/Space";
import ContextMenu from "../../../components/ContextMenu/ContextMenu";
import Input from "../../../components/Input/Input";


const ListPicker = (props: {items: any[], onChange: (e)=>void, value?: string, lang?: "hy" | "en", options?: any})=>{
    const {items, onChange, value, lang = "hy", options = {}} = props;

    const [active, setActive] = useState(null);
    const [search, setSearch] = useState("");

    const selected = useMemo(()=>{
        return value?.length? value.split(",")?.map(x =>Number(x)): [];
    }, [value]);

    const itemsFiltered = useMemo(()=>{
        const itemsVal = items.filter(x => selected.indexOf(x.id) === -1);
        return search?.length ? itemsVal.filter(x => conditionSearch(x.title_hy, search) || conditionSearch(x.title_en, search)) : itemsVal;
    }, [search, items, selected]);

    return <Div column className={"list-picker"}>
        <Div column={true} gap={4}>
            {items?.filter(x => selected.indexOf(x.id) > -1).length? items?.filter(x => selected.indexOf(x.id) > -1)?.map(m=>{
                return <ListItem
                    hover={true}
                    title={m["title_" +lang]} size={"lg"}
                    leftIcon={options.viewImage? <img src={m.image}/> : undefined} key={"item-" + m.id}
                    rightIcon={<Button size={"xsm"} onClick={()=>onChange(selected.filter(x => x !== m.id).join(","))} color={"red"} title={"X"}/>}
                />
            }) : <Text align={"center"} color={"grey"}>No selected items</Text>}
        </Div>
        <Space/>
        <Button onClick={(e)=>setActive(e.target.closest(".button"))} title={"Ավելացնել նորը"} size={"xsm"} color={"green"}/>
        {active && <ContextMenu
            size={"sm"}
            noScrollTop={<><Div alignV={"center"}>
                <Space size={'xsm'} vertical={false}/>
                <Input autofocus={true} placeholder={"Search"} onChange={setSearch} value={search}/>
                <Space size={'xsm'} vertical={false}/>
            </Div><Space size={"xsm"}/></>}
            onClose={()=>setActive(null)}
            alignTo={active}
            content={itemsFiltered?.length ?itemsFiltered?.map(m=>{
                return <ListItem onClick={()=>{
                    onChange([...selected, m.id].join(","));
                    setSearch("");
                    setActive(null);
                }} hover={true} key={"items-" + m.id} leftIcon={options.viewImage? <img src={m.image}/>: undefined} title={m["title_" +lang]}/>
            }) : [<Text align={"center"} color={"grey"}>No items</Text>]}
        />}
    </Div>
}

export default ListPicker;
