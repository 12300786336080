import * as React from 'react';
import classNames from "classnames";
import './Div.scss';
import {useEffect, useRef, useState} from "react";
const Div = (props:{
    id?: string,
    className?: string,
    children: any,
    column?: boolean,
    alignH?: "center" | "end" | "between",
    alignV?: "center" | "end" | "between",
    gap?: number
    style?: any,
    width?: "full" | "page" | "none"
    onClick?: (e)=>void,
    onMouseOver?: (e) => void,
    onMouseOut?: () => void,
    overflowHidden?: boolean | "x" | "y",
    loading?: boolean,
    getRef?: (e)=>void,
    attr?: any,
    wrap?: "mobile" | true | "mobile-center",
}) => {
    const {id, children, column, alignH = "", alignV = "", gap, className = "", style = {}, width, onClick = undefined, onMouseOver = undefined, onMouseOut = undefined, overflowHidden, loading, getRef, attr, wrap} = props;
    if(gap) style.gap = gap;
    const ref = useRef();
    const divClass = classNames("div", className, {
        "column": column,
        "align-center": alignH === "center",
        "align-end": alignH === "end",
        "justify-center": alignV === "center",
        "justify-end": alignV === "end",
        "justify-between": alignV === "between",
        "width-full": width === "full",
        "page-width": width === "page",
        "n-width": width === "none",
        "pointer": onClick,
        "wrap-m": wrap === "mobile",
        "wrap-m-c": wrap === "mobile-center",
        "wrap": wrap === true,
        "overflow-hidden": overflowHidden === true,
        "overflow-hidden-x": overflowHidden === "x",
        "overflow-hidden-y": overflowHidden === "y",
        loading: loading,
    });


    useEffect(()=>{
        if(ref && getRef) getRef(ref);
    }, [ref])

    return <div id={id} {...attr} ref={getRef? ref: undefined} onClick={onClick} onMouseOver={onMouseOver} onMouseOut={onMouseOut} className={divClass} style={style}>{children}</div>
};
export default Div;