import React, {useEffect, useState} from 'react';
import "./MediaLibraryPage.scss";
import Div from "../../components/Div/Div";
import MainSlider from "../../components/MainSlider/MainSlider";
import Space from "../../components/Space/Space";
import {lang, lg} from "../../helpers/lang/lang";
import Text from "../../components/Text/Text";
import {Link} from "react-router-dom";
import PlusButton from "../../components/PlusButton/PlusButton";
import {useParams} from "react-router";
import SimpleScroller from "../../components/SimpleScroller/SimpleScroller";
import {BsPlayCircle} from "react-icons/bs";
import {conn, uploads_url} from "../../helpers/request/connect";
import Preloader from "../../admin/components/Preloader/Preloader";
import {getImage, getVideoCover, langChoose} from "../../helpers";
import VideoPlayer from "../../components/VideoPlayer/VideoPlayer";
import VideoPage from "./VideoPage";
import CatalogsPage from "./CatalogsPage";
import {RiDownloadCloudFill} from "react-icons/ri";
import PublicationsPage from "./PublicationsPage";


const MediaLibraryPage = () => {

    const [loading, setLoading] = useState(true);
    const params = useParams();
    const [data, setData] = useState<any>({});
    const [selectedVideo, setSelectedVideo] = useState(null);

    useEffect(()=>{

       if(!params?.p2){ conn("web/getMediaLibrary").then((res:any)=>{
           setData(res);
           setLoading(false);
        });}else setLoading(false);
    }, [params?.p2])



    return (
        <Div column className={"media-library-content"}>
            <Preloader active={loading}/>
            {params.p2 === "videos" &&
                    <VideoPage/>
                || params.p2 === "catalogs" && <CatalogsPage/>
                || params.p2 === "publications" && <PublicationsPage/>
                || <><MainSlider slides={[{
                text1: lang["media-library"],
                text3: lang["media-library-slogan"],
                image: getImage(data?.cover),
            }]}/>

                <Div className={"width"} column>
                    <Space size={"xlg"}/>
                    <Text size={"lg"} color={"main"}>{lang["brochures-catalogs-flyers"]}</Text>
                    <Space size={"sm"}/>
                    <Text uppercase size={"md"} color={"grey-light"}>{lang["popular-catalogs"]}</Text>
                </Div>
                    <Space size={"lg"}/>
                    <Div className={"media-library-gradient"}>
                        <Div className={"width"} column>

                    <SimpleScroller items={data?.catalogs?.map(item=>{
                        return <Link key={"item-id-" + item.id}  to={uploads_url + "/files/" + item.file} target={"_blank"}><Div className={"media-library-item"}>
                            <img src={getImage(item.image)}/>
                            <Div className={"media-library-item-play"}><RiDownloadCloudFill/></Div>
                            <Div className={"media-library-item-title"}><Text size={"md"} align={"center"} color={"light"}>{langChoose(item, "title_", lg)}</Text></Div>
                        </Div></Link>

                    })}/>
                        </Div>
                    </Div>
                <Space/>
                    <Div alignV={"end"} className={"width"}> <Link to={"/" + lg + "/media-library/catalogs"}><PlusButton title={lang["see-all"]}/></Link></Div>
                <Space size={"md"}/>
                <Div className={"width"} column>
                    <Space size={"xlg"}/>
                    <Text size={"lg"} color={"main"}>{lang["videos"]}</Text>
                    <Space size={"sm"}/>
                    <Text uppercase size={"md"} color={"grey-light"}>{lang["all-our-videos-here"]}</Text>
                </Div>
                    <Space size={"lg"}/>
                    <Div className={"media-library-gradient"}>
                        <Div className={"width"} column>

                    <SimpleScroller items={data?.videos?.map(item=>{
                        return <Div onClick={()=>setSelectedVideo(item)} key={"item-id-" + item.id} className={"media-library-item"}>
                            <img src={getVideoCover(item)}/>
                            <Div className={"media-library-item-play"}><BsPlayCircle/></Div>
                            <Div className={"media-library-item-title"}><Text size={"md"} align={"center"} color={"light"}>{langChoose(item, "title_", lg)}</Text></Div>
                        </Div>

                    })}/>
                        </Div>
                    </Div>
                <Space/>
                    <Div alignV={"end"} className={"width"}> <Link to={"/" + lg + "/media-library/videos"}><PlusButton title={lang["see-all"]}/></Link></Div>
                <Space size={"md"}/>
                <Div className={"width"} column>
                    <Space size={"xlg"}/>
                    <Text size={"lg"} color={"main"}>{lang["publications"]}</Text>
                    <Space size={"sm"}/>
                    <Text uppercase size={"md"} color={"grey-light"}>{lang["all-our-publications"]}</Text>
                </Div>
                    <Space size={"lg"}/>
                    <Div className={"media-library-gradient"}>
                        <Div className={"width"} column>

                    <SimpleScroller items={data?.publications?.map(item=>{
                        return <Div key={"media-library-item" + item.id} className={"media-library-item"}>
                            <img src={getImage(item.image)}/>
                            <Div className={"media-library-item-title"}><Text size={"md"} align={"center"} color={"light"}>{langChoose(item, "title_", lg)}</Text></Div>
                        </Div>

                    })}/>
                        </Div>
                    </Div>
                <Space/>
                    <Div alignV={"end"} className={"width"}><Link to={"/" + lg + "/media-library/publications"}><PlusButton title={lang["see-all"]}/></Link></Div>
                <Space size={"md"}/>
                </>}
            {selectedVideo? <VideoPlayer video={selectedVideo} onClose={()=>setSelectedVideo(null)}/>: null}
        </Div>
    );
};

export default MediaLibraryPage;
