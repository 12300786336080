import React, {useEffect, useRef} from 'react';
import "./EditorTextViewer.scss";

const EditorTextViewer = (props: {text: string}) => {

    const ref = useRef();

    useEffect(()=>{
        // @ts-ignore
        ref.current.innerHTML = props.text;

    }, [ref, props.text]);


    return (
        <div className={"text-viewer"} ref={ref}>
        </div>
    );
};

export default EditorTextViewer;
