import React from 'react';
import Div from "../Div/Div";
import Text from "../Text/Text";
import "./Footer.scss";
import Space from "../Space/Space";
import {Link} from "react-router-dom";
import {AiOutlineMail} from "react-icons/ai";
import {FaPhoneFlip} from "react-icons/fa6";
import {lang, lg} from "../../helpers/lang/lang";

const logo = require("./../../images/logo.svg");

const Footer = () => {
    return (
        <Div className={"footer"} column>
            <Div className={"footer-border"}>
                <Div className={"footer-in"} column>
                    <Div className={"footer-head"}>
                        <Link to={"/"}><img src={logo.default} className={"footer-logo"}/></Link>
                        <Div className={"footer-soc-content"}>
                            <Text color={"grey-light"}>{lang["we-are-in-social-networks"]}: </Text>
                            <Link to="https://www.facebook.com/profile.php?id=61553909384723" target={"_blank"}>
                                <img src={require("./../../images/fb.svg")?.default}/>
                            </Link>
                            <Link to="https://instagram.com" target={"_blank"}>
                                <img src={require("./../../images/insta.svg")?.default}/>
                            </Link>
                            <Link to="https://twitter.com" target={"_blank"}>
                                <img src={require("./../../images/x.svg")?.default}/>
                            </Link>
                        </Div>
                    </Div>
                    <Space line/>
                    <Space size={"lg"}/>
                    <Div className={"footer-info-content"}>

                        <Div className={"footer-item"} column gap={12}>
                            <Link to={"/"}><Text color={"main"}>{lang["home"]}</Text></Link>
                            <Link to={"/" + lg + "/services"}><Text color={"main"}>{lang["services"]}</Text></Link>
                            <Link to={"/" + lg + "/media-library"}><Text color={"main"}>{lang["media-library"]}</Text></Link>
                            <Link to={"/" + lg + "/events"}><Text color={"main"}>{lang["events"]}</Text></Link>
                            <Link to={"/" + lg + "/blog"}><Text color={"main"}>{lang["blog"]}</Text></Link>
                        </Div>
                        <Div className={"footer-item"} column gap={12}>
                            <Text size={"sm"} color={"grey-light"}>{lang["how-to-contact-us"]}</Text>
                            <Div gap={10} alignH={"center"}><AiOutlineMail/><Text size={"md"} color={"main"}>info@medstandard.am</Text></Div>
                            <Div gap={10} alignH={"center"}><FaPhoneFlip /><Text size={"md"} color={"main"}>(997) 008-0074</Text></Div>
                            <Div gap={10} alignH={"center"}><FaPhoneFlip /><Text size={"md"} color={"main"}>(997) 008-0075</Text></Div>
                        </Div>
                        <Div className={"footer-item"} column gap={12}>
                            <Text size={"sm"} color={"grey-light"}>{lang["where-to-find-us"]}</Text>
                            <Text className={"footer-address"} size={"md"} color={"main"}>MedExpert Netherlands, Groot Hertoginnelaan 8X, 2517EG, The Hague, Netherlands</Text>
                        </Div>
                    </Div>

                </Div>
            </Div>
            <Space/>
            <Text align={"center"} color={"grey-light"}>@MedStandard by <Link to={"http://pixi.am/?tk=18408tmurm9r7cdqrangjb16x1atrrhs0mt8ff272cy9"} target={"_blank"}><Text color={"main"}>Pixi Studio</Text></Link></Text>
        </Div>
    );
};

export default Footer;
