import React, {useEffect, useState} from 'react';
import Div from "../components/Div/Div";
import Text from "../components/Text/Text";
import Space from "../components/Space/Space";
import Button from "./components/Button/Button";
import ListItem from "../components/ListItem/ListItem";
import {conn, uploadFile} from "../helpers/request/connect";
import Dialog from "./components/Dialog/Dialog";
import Uploader from "./components/Uploader/Uploader";
import {dateToday, getDate, getImage} from "../helpers";
import Input from "../components/Input/Input";
import Tabs from "./components/Tabs/Tabs";
import Editor from "./components/Editor/Editor";
import Preloader from "./components/Preloader/Preloader";
import LangTabs from "./components/LangTabs/LangTabs";
import ListPicker from "./components/ListPicker/ListPicker";
import {Select} from "./components/Select/Select";

const Events = () => {

    const [items, setItems] = useState([]);
    const [info, setInfo] = useState<any>({});
    const [editContext, setEditContext] = useState(false);
    const [remove, setRemove] = useState(null);
    const [loading, setLoading] = useState(true);
    const [topics, setTopics] = useState([]);
    const [disciplines, setDisciplines] = useState([]);


    useEffect(() => {
        conn("events/get").then((res: any) => {
            if(res.response) setItems(res?.response);
        conn("topics/get").then((top:any)=>{
            if(top.response) setTopics(top.response);
            conn("disciplines/get").then((dis:any)=> {
                if (dis.response) setDisciplines(dis.response);
                setLoading(false);
            });
        });
        })
    }, [])

    useEffect(()=>{
        if(info.id) conn("topics/connects", {id: info.id, type: "events"}).then((res:any)=>{
            if(res.response) setInfo(prev=>{
                return {...prev, topics: res.response?.map(x=>x.topic_id).join(",")}
            });
        })
    }, [info?.id])

    const saveDoc = (data) => {
        conn("events/save", data).then((res) => {
            window.location.reload();
        });
    }


    return (
        <Div column>
            <Preloader active={loading}/>
            <Space/>
            <Text size={"lm"} color={"main"}>Events</Text>
            <Space/>
            <Button onClick={() => setEditContext(true)} stretch={"fixed"} title={"Add new"} color={"green"}/>
            <Space/>
            <Div column>
                {items.map(item => {
                    return <ListItem
                        key={"slide-" + item.id}
                        hover
                        onClick={() => {
                            setInfo(item);
                            setEditContext(true);
                        }}
                        title={<Div gap={4}>{item.sort} {item.title_hy?.length ? item.title_hy : "Untitled"}
                        </Div>}/>
                })}
            </Div>

            {/*///////////////////// edit /////////////////////*/}

            {editContext && <Dialog
                size={"full"}
                title={"Event"}
                onClose={() => {
                    setInfo({});
                    setEditContext(false);
                }}
            >
                <Input type={"date"} label={"Date"} placeholder={"Date"} value={info.date ?? dateToday}
                       onChange={(e) => setInfo(prev => {
                           return {...prev, date: e}
                       })}/>
                <Space/>
                <Input label={"Link"} placeholder={"Link"} value={info.link}
                       onChange={(e) => setInfo(prev => {
                           return {...prev, link: e}
                       })}/>
                <Space/>
                <Text color={"grey-light"} size={"xsm"}>Discipline</Text>
                <Space size={"xsm"}/>
                <Select selected={info.discipline_id} onChange={(e)=>setInfo(prev=>{return{...prev, discipline_id: e.id}})} search={true} options={disciplines?.map(dis=>{return {title: dis.title_hy, id: dis.id}})}/>

                <Space/>

                <LangTabs tabs={[

                    {
                        title: "Հայերեն",
                        content: <Div column>
                            <Input stretch={"full"} label={"Անվանում"} placeholder={"Անվանում"} value={info.title_hy}
                                   onChange={(e) => setInfo(prev => {
                                       return {...prev, title_hy: e}
                                   })}/>
                        </Div>
                    },
                    {
                        title: "English",
                        content: <Div column>
                            <Input stretch={"full"} label={"Title"} placeholder={"Title"} value={info.title_en}
                                   onChange={(e) => setInfo(prev => {
                                       return {...prev, title_en: e}
                                   })}/>
                        </Div>
                    },
                    {
                        title: "Русский",
                        content: <Div column>
                            <Input stretch={"full"} label={"Название"} placeholder={"Название "}
                                   value={info.title_ru} onChange={(e) => setInfo(prev => {
                                return {...prev, title_ru: e}
                            })}/>
                            </Div>
                    }
                ]}/>
                <Space/>
                <Text color={"grey-light"} size={"xsm"}>Topics</Text>
                <ListPicker items={topics?.map(top=>top)} lang={"hy"} onChange={(e)=>setInfo(prev=>{
                    return {...prev, topics: e}
                })} value={info.topics}/>
                <Space size={"xsm"}/>
                <Space/>
                <Div gap={12} alignV={"end"}>
                    <Button title={"Cancel"} onClick={() => {
                        setInfo({});
                        setEditContext(false);
                    }} color={"transparent"}/>
                    <Button stretch={"fixed"} title={"Save"} onClick={() => {
                        if(!info.title_hy?.length){
                            alert("Fill in required fields");
                        }else{
                            setLoading(true);
                            saveDoc({...info, date: info.date ?? dateToday});
                        }
                    }} color={"green"}/>
                    {info.id ? <Button title={"Delete"} onClick={() => setRemove(info.id)} color={"red"}/> : null}
                </Div>
            </Dialog>}

            {/*///////////////////// remove /////////////////////*/}

            {remove && <Dialog
                size={"xsm"}
                title={"Delete"}
                onClose={() => setRemove(null)}
                buttons={{
                    right: <Div>
                        <Button title={"Cancel"} color={"transparent"} onClick={() => setRemove(null)}/>
                        <Button title={"Delete"} color={"red"} onClick={() => {
                            conn("events/remove", {id: info.id}).then(() => window.location.reload())
                        }}/>
                    </Div>
                }}
            >
                <Text>Do you want to delete this?</Text>
            </Dialog>}
        </Div>
    );
};

export default Events;
