import React from 'react';
import "./Table.scss";
import Div from "../Div/Div";
import Text from "../Text/Text";
import {Link} from "react-router-dom";
import {lang} from "../../helpers/lang/lang";

const Table = (props:{
    headers: any[],
    list: any[]
}) => {
    const {list, headers} = props;
    return (
        <Div key={"table-item"} className={"table width"} column>
            <Div className={"table-header"}>
                {headers?.map(head=>{
                    return <Div key={"item" + head.id} className={`table-header-item ${head.size ?? ""}`}>
                        <Text color={"light"} singleLine weight={"bold"}>{head.title}</Text>
                    </Div>
                })}
            </Div>
                {list?.length ? list.map((l, i)=>{
                    const listItem = <Div key={"row-item-" + i} className={`table-row ${l.is_over? "over-time":""}`}>
                        {headers.map(head=>{
                            return <Div key={"head-" + head.id} className={`table-row-item ${headers.find(x => x.id === head.id)?.size ?? ""}`} alignH={"center"}>
                                <Text color={"light"} linesCount={2}>
                                    {l[head?.id]}
                                </Text>
                            </Div>
                        })}

                    </Div>;
                    return l.link? <Link to={l.link} target={"_blank"}>{listItem}</Link> : listItem;
                }): <Text align={"center"} className={"no-result"}>{lang["no-result"]}</Text>}
        </Div>
    );
};

export default Table;
