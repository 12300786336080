import React, {useEffect, useState} from 'react';
import Div from "../components/Div/Div";
import Text from "../components/Text/Text";
import Space from "../components/Space/Space";
import Button from "./components/Button/Button";
import ListItem from "../components/ListItem/ListItem";
import {conn, uploadFile} from "../helpers/request/connect";
import Dialog from "./components/Dialog/Dialog";
import Uploader from "./components/Uploader/Uploader";
import {dateToday, getDate, getImage} from "../helpers";
import Input from "../components/Input/Input";
import Editor from "./components/Editor/Editor";
import Preloader from "./components/Preloader/Preloader";
import LangTabs from "./components/LangTabs/LangTabs";

const Services = () => {

    const [items, setItems] = useState([]);
    const [info, setInfo] = useState<any>({});
    const [editContext, setEditContext] = useState(false);
    const [remove, setRemove] = useState(null);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        conn("services/get").then((res: any) => {
            if (res.response) setItems(res?.response);
            setLoading(false);
        })
    }, [])

    const saveDoc = (data) => {
        conn("services/save", data).then((res) => {
            window.location.reload();
        });
    }


    return (
        <Div column>
            <Preloader active={loading}/>
            <Space/>
            <Text size={"lm"} color={"main"}>Services</Text>
            <Space/>
            <Button onClick={() => setEditContext(true)} stretch={"fixed"} title={"Add new"} color={"green"}/>
            <Space/>
            <Div column>
                {items.map(item => {
                    return <ListItem
                        key={"slide-" + item.id}
                        hover
                        onClick={() => {
                            setInfo(item);
                            setEditContext(true);
                        }}
                        title={<Div gap={4}>{item.sort} {item.title_hy?.length ? item.title_hy : "Untitled"}
                        </Div>}/>
                })}
            </Div>

            {/*///////////////////// edit /////////////////////*/}

            {editContext && <Dialog
                size={"full"}
                title={"Service"}
                onClose={() => {
                    setInfo({});
                    setEditContext(false);
                }}
            >
                <Uploader
                    value={info.image ? (typeof info.image === "string" ? getImage(info.image) : URL.createObjectURL(info.image)) : undefined}
                    onChange={(e) => setInfo(prev => {
                        return {...prev, image: e}
                    })}
                    size={"ml"}
                />
                <Space/>
                <Input type={"number"} label={"Sort"} placeholder={"Sort"} value={info.sort ?? "0"}
                       onChange={(e) => setInfo(prev => {
                           return {...prev, sort: e}
                       })}/>
                <Space/>
                <LangTabs tabs={[

                    {
                        title: "Հայերեն",
                        content: <Div column>
                            <Input stretch={"full"} label={"Անվանում"} placeholder={"Անվանում"} value={info.title_hy}
                                   onChange={(e) => setInfo(prev => {
                                       return {...prev, title_hy: e}
                                   })}/>
                            <Space/>
                            <Text color={"grey-light"} size={"xsm"}>Տեքստ</Text>
                            <Space size={"xsm"}/>
                            <Editor key={"text-hy"} onChange={(e) => setInfo(prev => {
                                return {...prev, text_hy: e}
                            })} value={info.text_hy}/>
                        </Div>
                    },
                    {
                        title: "English",
                        content: <Div column>
                            <Input
                                stretch={"full"}
                                label={"Title"}
                                placeholder={"Title"}
                                value={info.title_en}
                                onChange={(e) => setInfo(prev => {
                                    return {...prev, title_en: e}
                                })}
                            />
                            <Space/>
                            <Text color={"grey-light"} size={"xsm"}>Text</Text>
                            <Space size={"xsm"}/>
                            <Editor
                                key={"text-en"}
                                onChange={(e) => setInfo(prev => {
                                    return {...prev, text_en: e}
                                })}
                                value={info.text_en}
                            />

                        </Div>
                    },
                    {
                        title: "Русский",
                        content: <Div column>
                            <Input
                                stretch={"full"}
                                label={"Название"}
                                placeholder={"Название "}
                                value={info.title_ru}
                                onChange={(e) => setInfo(prev => {
                                    return {...prev, title_ru: e}
                                })}
                            />
                            <Space/>
                            <Text color={"grey-light"} size={"xsm"}>Текст</Text>
                            <Space size={"xsm"}/>
                            <Editor
                                key={"text-ru"}
                                onChange={(e) => setInfo(prev => {
                                    return {...prev, text_ru: e}
                                })}
                                value={info.text_ru}
                            />

                        </Div>
                    }
                ]}/>

                <Space/>
                <Div gap={12} alignV={"end"}>
                    <Button title={"Cancel"} onClick={() => {
                        setInfo({});
                        setEditContext(false);
                    }} color={"transparent"}/>
                    <Button stretch={"fixed"} title={"Save"} onClick={() => {
                        if (!info.title_hy?.length) {
                            alert("Fill in required fields");
                        } else {
                            setLoading(true);
                            if(typeof info.image === "string" || !info.image){
                                saveDoc(info);
                            }else{
                                uploadFile(info.image).then((res:any)=>{
                                    if(res.response) saveDoc({...info, image: res.response});
                                    else alert("Image upload error");
                                });
                            }
                        }
                    }} color={"green"}/>
                    {info.id ? <Button title={"Delete"} onClick={() => setRemove(info.id)} color={"red"}/> : null}
                </Div>
            </Dialog>}

            {/*///////////////////// remove /////////////////////*/}

            {remove && <Dialog
                size={"xsm"}
                title={"Delete"}
                onClose={() => setRemove(null)}
                buttons={{
                    right: <Div>
                        <Button title={"Cancel"} color={"transparent"} onClick={() => setRemove(null)}/>
                        <Button title={"Delete"} color={"red"} onClick={() => {
                            conn("services/remove", {id: info.id}).then(() => window.location.reload())
                        }}/>
                    </Div>
                }}
            >
                <Text>Do you want to delete this?</Text>
            </Dialog>}
        </Div>
    );
};

export default Services;
