import {Cookies} from "react-cookie";
import {base_url, uploads_url} from "./request/connect";

export const isValidEmail = (email: string) => {
    return /\S+@\S+\.\S+/.test(email);
}


export const getImage = (img?: string, type = "images") => {
    if (type === "youtube") return "https://i.ytimg.com/vi_webp/" + img + "/sddefault.webp";
    else return uploads_url + "/" + (img? type + "/" + img : "no-image.jpg");

}

export const getZero = (x: number) => {
    return Number(x) < 10 ? "0" + x : x;
}

export const getDate = (date: number, show_time?: boolean) => {
    const d = new Date(date * 1000);
    return getZero(d.getDate()) + "/" + getZero(d.getMonth() + 1) + "/" + d.getFullYear() + (show_time ? " " + getZero(d.getHours()) + ":" + getZero(d.getMinutes()) : "");
}

const d = new Date();
export const dateToday = d.getFullYear() + "-" + getZero(d.getMonth() + 1) + "-" + getZero(d.getDate());

export const getDateInterval = (date: number) => {
    const now = unix(new Date().getTime());
    const difference = now - Number(date);

    return difference;
}

export const unix = (t: number) => Math.floor(Number(t) / 1000);


export const getVideoCover = (video) => {
    if (video?.link) return "https://i.ytimg.com/vi_webp/" + video?.link + "/sddefault.webp";
    else return uploads_url + "/images/" + video?.image;
}


const getExpires = (days: number) => {
    const expires = new Date();
    expires.setTime(expires.getTime() + 3600 * 1000 * 24 * days);
    return expires;
}
const cooks = new Cookies();
export const cookies = {
    set: async (key: string, value: string, days?: number) => {
        const options: any = {path: "/"};
        if (days) options.expires = getExpires(days);
        cooks.set(key, value, options);
    },
    get: (key: string) => {
        return cooks.get(key);
    }
}

export const catalogTypes = ["catalog", "brochure", "flyer"];

export const sortBy = (arr, by) => {
    return arr.sort((a: any, b: any) => {
        return Number(a[by] - b[by]);
    })
}

export const removeTags = (str) => {
    const div = document.createElement("div");
    div.innerHTML = str;
    return div.innerText;
}

export const langChoose = (obj, key, lang, clearTags = false) => {
    const str =  obj?.[key + lang]?.length ? obj?.[key + lang] : (
        obj?.[key + "en"]?.length ? obj?.[key + "en"] : (
            obj?.[key + "hy"]?.length ? obj?.[key + "hy"] : (
                obj?.[key + "ru"]?.length ? obj?.[key + "ru"] : ""
            )
        )
    );
    return clearTags ? removeTags(str) : str;
}


