import React, {useEffect} from 'react';
import Div from "../../components/Div/Div";
import {useParams} from "react-router";
import SlidesPage from "../SlidesPage/SlidesPage";
import Disciplines from "../Disciplines";
import Topics from "../Topics";
import Videos from "../Videos";
import Catalogs from "../Catalogs";
import Publications from "../Publications";
import Languages from "../Languages";
import Journals from "../Journals";
import Events from "../Events";
import Blog from "../Blog";
import Partners from "../Partners";
import Services from "../Services";
import Footer from "../../components/Footer/Footer";
import Space from "../../components/Space/Space";
import Projects from "../Projects";
import Products from "../ProductCategory";
import ProductCategory from "../ProductCategory";
import ProductsPage from "../ProductsPage";


const AdminMainPage = () => {
    const params = useParams();

    useEffect(()=>{
        console.log("params", params);
    }, [params])

    return (
        <Div column>
            <Div className={"width"} column style={{minHeight: window.innerHeight - 500}}>
                {params.p1?.toLowerCase() === "slides" && <SlidesPage/>
                    || params.p1?.toLowerCase() === "disciplines" && <Disciplines/>
                    || params.p1?.toLowerCase() === "catalogs" && <Catalogs/>
                    || params.p1?.toLowerCase() === "publications" && <Publications/>
                    || params.p1?.toLowerCase() === "languages" && <Languages/>
                    || params.p1?.toLowerCase() === "journals" && <Journals/>
                    || params.p1?.toLowerCase() === "events" && <Events/>
                    || params.p1?.toLowerCase() === "blog" && <Blog/>
                    || params.p1?.toLowerCase() === "topics" && <Topics/>
                    || params.p1?.toLowerCase() === "videos" && <Videos/>
                    || params.p1?.toLowerCase() === "partners" && <Partners/>
                    || params.p1?.toLowerCase() === "services" && <Services/>
                    || params.p1?.toLowerCase() === "products-categories" && <ProductCategory/>
                    || params.p1?.toLowerCase() === "products" && <ProductsPage/>
                    || <>Stat page</>}

            </Div>
            <Space/>
            <Footer/>
        </Div>
    );
};

export default AdminMainPage;
