import React, {useEffect, useState} from 'react';
import Div from "../../components/Div/Div";
import Text from "../../components/Text/Text";
import Space from "../../components/Space/Space";
import Button from "../components/Button/Button";
import ListItem from "../../components/ListItem/ListItem";
import {conn, uploadFile} from "../../helpers/request/connect";
import Dialog from "../components/Dialog/Dialog";
import Uploader from "../components/Uploader/Uploader";
import {getImage} from "../../helpers";
import Input from "../../components/Input/Input";
import Tabs from "../components/Tabs/Tabs";
import Editor from "../components/Editor/Editor";
import Preloader from "../components/Preloader/Preloader";
import LangTabs from "../components/LangTabs/LangTabs";

const SlidesPage = () => {

    const [items, setItems] = useState([]);
    const [info, setInfo] = useState<any>({});
    const [editContext, setEditContext] = useState(false);
    const [remove, setRemove] = useState(null);
    const [loading, setLoading] = useState(true);
    const [cover, setCover] = useState<any>({})

    useEffect(()=>{
        conn("slides/getSlides").then((res:any)=>{
            if(res.response) setItems(res?.response);
            if(res.cover) setCover(res.cover);
            setLoading(false);
        })
    }, [])

    const saveDoc = (data)=>{
        conn("slides/saveSlide", data).then(()=>{ window.location.reload(); });
    }

    const coverImageUpload = (data)=>{
        setLoading(true);
        if(typeof data.image === "string"){
         saveCover(data);
        }else {
            uploadFile(data.image).then((res:any)=>{
                saveCover({...data, image: res.response});
            });
        }
    }

    const saveCover = (data)=>{

        conn("slides/saveCover", data).then(()=> {
            setLoading(false);
            setCover(prev=>{
                return {...prev, [`${data.link}`]: data.image}
            });
        });
    }

    useEffect(()=>{
        console.log(cover);
    }, [cover])


    return (
        <Div column>
            <Preloader active={loading}/>
            <Space/>
            <Text size={"lm"} color={"main"}>Slides</Text>
            <Space/>
            <Button onClick={()=>setEditContext(true)} stretch={"fixed"} title={"Add new"} color={"green"}/>
            <Space/>
            <Div column>
                {items.map(slide=>{
                    return <ListItem
                        key={"slide-" + slide.id}
                        hover
                        onClick={()=>{
                            setInfo(slide);
                            setEditContext(true);
                        }}
                        title={<Div gap={4}>{slide.sort} {slide.text_1_hy?.length? slide.text_1_hy : "Untitled slide"}
                    </Div>}/>
                })}

                <Space/>
                <Text color={"main"} size={"lm"}>Services cover</Text>
                <Space size={"xsm"}/>
                <Uploader
                    value={cover.services ? (typeof cover.services === "string" ? getImage(cover?.services) : URL.createObjectURL(cover?.services)) : undefined}
                    onChange={(e)=>setCover(prev=>{return{...prev, services: e}})}
                    size={"ml"}
                />
                <Space size={"xsm"}/>
                {cover.services && typeof cover.services !== "string" ?<Button color={"green"} title={"Save"} size={"xsm"} onClick={() => {
                    coverImageUpload({link: "services", image: cover.services});
                }}/>: null}
                <Space/>
                <Text color={"main"} size={"lm"}>Media library cover</Text>
                <Space size={"xsm"}/>
                <Uploader
                    value={cover.media ? (typeof cover.media === "string" ? getImage(cover.media) : URL.createObjectURL(cover.media)) : undefined}
                    onChange={(e)=>setCover(prev=>{return{...prev, media: e}})}
                    size={"ml"}
                />
                <Space size={"xsm"}/>
                {cover.media && typeof cover.media !== "string" ?<Button color={"green"} title={"Save"} size={"xsm"} onClick={() => {
                    coverImageUpload({link: "media", image: cover.media});
                }}/>: null}
                <Space/>
                <Text color={"main"} size={"lm"}>Events cover</Text>
                <Space size={"xsm"}/>
                <Uploader
                    value={cover.events ? (typeof cover.events === "string" ? getImage(cover.events) : URL.createObjectURL(cover.events)) : undefined}
                    onChange={(e)=>setCover(prev=>{return{...prev, events: e}})}
                    size={"ml"}
                />
                <Space size={"xsm"}/>
                {cover.events && typeof cover.events !== "string" ?<Button color={"green"} title={"Save"} size={"xsm"} onClick={() => {
                    coverImageUpload({link: "events", image: cover.events});
                }}/>: null}
                <Space/>
            </Div>

            {/*///////////////////// edit /////////////////////*/}

            {editContext && <Dialog
                size={"full"}
                title={"Slide"}
                onClose={()=>{
                setInfo({});
                setEditContext(false);
            }}
            >
             <Uploader
                 value={info.image ? (typeof info.image === "string" ? getImage(info.image) : URL.createObjectURL(info.image)) : undefined}
                 onChange={(e)=>setInfo(prev=>{return{...prev, image: e}})}
                 size={"l"}
             />
                <Space/>
                <Input type={"number"} label={"Sort"} placeholder={"Sort"} value={info.sort ?? "0"} onChange={(e)=>setInfo(prev=>{return{...prev, sort: e}})}/>
                <Space/>
                <Input label={"Link"} placeholder={"Link"} value={info.link} onChange={(e)=>setInfo(prev=>{return{...prev, link: e}})}/>
            <Space/>
                <LangTabs tabs={[

                    {
                        title: "Հայերեն",
                        content: <Div column>
                            <Input stretch={"full"} label={"Սլայդի վերնագիր 1"} placeholder={"Սլայդի վերնագիր 1"} value={info.text_1_hy} onChange={(e)=>setInfo(prev=>{return{...prev, text_1_hy: e}})}/>
                            <Space/>
                            <Input stretch={"full"} label={"Սլայդի վերնագիր 2"} placeholder={"Սլայդի վերնագիր 2"} value={info.text_2_hy} onChange={(e)=>setInfo(prev=>{return{...prev, text_2_hy: e}})}/>
                            <Space/>
                            <Text color={"grey-light"} size={"xsm"}>Սլայդի տեքստ</Text>
                            <Space size={"xsm"}/>
                            <Editor key={"text-hy"} toolbar={false} size={"sm"} value={info.text_3_hy} onChange={(e)=> {
                                console.log("ed", e);
                                setInfo(prev => {
                                    return {...prev, text_3_hy: e}
                                });
                            }}/>
                            <Space/>
                        </Div>
                    },
                    {
                        title: "English",
                        content: <Div column>
                            <Input stretch={"full"} label={"Slide title 1"} placeholder={"Slide title 1"} value={info.text_1_en} onChange={(e)=>setInfo(prev=>{return{...prev, text_1_en: e}})}/>
                            <Space/>
                            <Input stretch={"full"} label={"Slide title 2"} placeholder={"Slide title 2"} value={info.text_2_en} onChange={(e)=>setInfo(prev=>{return{...prev, text_2_en: e}})}/>
                            <Space/>
                            <Text color={"grey-light"} size={"xsm"}>Slide text</Text>
                            <Space size={"xsm"}/>
                            <Editor key={"text-en"} toolbar={false} size={"sm"} value={info.text_3_en} onChange={(e)=>setInfo(prev=>{return{...prev, text_3_en: e}})}/>
                            <Space/>
                        </Div>
                    },
                    {
                        title: "Русский",
                        content: <Div column>
                            <Input stretch={"full"} label={"Название слайда 1"} placeholder={"Название слайда 1"} value={info.text_1_ru} onChange={(e)=>setInfo(prev=>{return{...prev, text_1_ru: e}})}/>
                            <Space/>
                            <Input stretch={"full"} label={"Название слайда 2"} placeholder={"Название слайда 2"} value={info.text_2_ru} onChange={(e)=>setInfo(prev=>{return{...prev, text_2_ru: e}})}/>
                            <Space/>
                            <Text color={"grey-light"} size={"xsm"}>Текст слайда</Text>
                            <Space size={"xsm"}/>
                            <Editor key={"text-ru"} toolbar={false} size={"sm"} value={info.text_3_ru} onChange={(e)=>setInfo(prev=>{return{...prev, text_3_ru: e}})}/>
                            <Space/></Div>
                    }
                ]}/>

                <Space/>
                <Div gap={12} alignV={"end"}>
                    <Button title={"Cancel"} onClick={()=>{
                        setInfo({});
                        setEditContext(false);
                    }} color={"transparent"}/>
                    <Button stretch={"fixed"} title={"Save"} onClick={()=>{
                        if(!info.image){
                            alert("Image is required");
                        }else{
                            setLoading(true);
                        if(typeof info.image === "string"){
                            saveDoc(info);
                        }else{
                            uploadFile(info.image).then((res:any)=>{
                                if(res.response) saveDoc({...info, image: res.response});
                                else alert("Image upload error");
                            });
                        }
                        }
                    }} color={"green"}/>
                    {info.id ?<Button title={"Delete"} onClick={() => setRemove(info.id)} color={"red"}/>: null}
                </Div>
            </Dialog>}

            {/*///////////////////// remove /////////////////////*/}

            {remove && <Dialog
                size={"xsm"}
                title={"Delete"}
                onClose={()=>setRemove(null)}
                buttons={{right: <Div>
                        <Button title={"Cancel"} color={"transparent"} onClick={()=>setRemove(null)} />
                        <Button title={"Delete"} color={"red"} onClick={()=>{
                            conn("slides/remove", {id: info.id}).then(()=>window.location.reload())
                        }} />
                    </Div>}}
            >
              <Text>Do you want to delete this?</Text>
            </Dialog>}
        </Div>
    );
};

export default SlidesPage;
