import React, {useEffect, useState} from 'react';
import Dialog from "./components/Dialog/Dialog";
import {conn, uploadFile} from "../helpers/request/connect";
import Preloader from "./components/Preloader/Preloader";
import {getImage, getVideoCover} from "../helpers";
import Uploader from "./components/Uploader/Uploader";
import Space from "../components/Space/Space";
import Text from "../components/Text/Text";
import Div from "../components/Div/Div";
import Button from "./components/Button/Button";
import {IoMdClose} from "react-icons/io";
import Input from "../components/Input/Input";
import LangTabs from "./components/LangTabs/LangTabs";
import Editor from "./components/Editor/Editor";
import {Select} from "./components/Select/Select";
import ListPicker from "./components/ListPicker/ListPicker";

const ProductEditPage = (props: { onClose, cat_id, id }) => {
    const {onClose, cat_id, id} = props;

    const [info, setInfo] = useState<any>({category_id: cat_id});
    const [loading, setLoading] = useState(true);
    const [progress, setProgress] = useState(0);
    const [categories, setCategories] = useState([]);
    const [disciplines, setDisciplines] = useState([]);
    const [videos, setVideos] = useState([]);
    const [topics, setTopics] = useState([]);
    const [remove, setRemove] = useState(null);



    useEffect(() => {
        if (id && id !== "new") {
            setLoading(true);
            conn("products/getProduct", {id: id}).then((res: any) => {
                if (res.response) setInfo(res.response);
            });
        } else {
            setLoading(false)
        }
    }, [id]);

    useEffect(() => {
            conn("topics/get").then((top:any)=>{
                if(top.response) setTopics(top.response);
                conn("disciplines/get").then((dis:any)=>{
                    if(dis.response) setDisciplines(dis.response);
                    conn("videos/get").then((dis:any)=>{
                        if(dis.response) setVideos(dis.response);
                        conn("products/getCategories").then((res: any) => {
                            if (res.response) {
                                setCategories(res.response);
                                if(!cat_id) setInfo(prev=>{return{...prev, category_id: res.response?.[0]?.id}})
                            }
                            setLoading(false);
                        });
                    });
                });
            });

    }, []);

    useEffect(() => {
        conn("products/getCategories").then((res: any) => {
            if (res.response) setCategories(res.response);
        });
    }, [])

    const saveDoc = (data) => {
        console.log(data);
         conn("products/saveProduct", data).then(() => {
              window.location.reload();
         });
    }


    const uploadFileFunc = async (file, type) => {
        if (typeof file === "string") return file;
        else {
            const res: any = await uploadFile(file, type, false, (e) => type === "file" ? setProgress(e.loaded / (e.total / 100)) : 0);
            if (res.response) return res.response;
            else {
                alert("File upload error");
                setLoading(false);
            }
        }
    }


    return (<>
            <Preloader active={loading}/>
            <Dialog autoFocus={false} buttons={{right: [<Div gap={6} key={"dialog-btn"}>
                    <Button key={"cancel"} title={"Cancel"} color={"transparent"} onClick={onClose}/>
                    {info.id? <Button key={"delete"} onClick={()=>setRemove(info.id)} title={"Delete"} color={"red"}/> : null}
                    <Button  key={"save"} title={"Save"} color={"green"} onClick={async()=> {
                        setLoading(true);
                        const img = (info.image && typeof info.image !== "string")? await uploadFileFunc(info.image, "simple") : (info.image ?? "");
                        const file = (info.file && typeof info.file !== "string")? await uploadFileFunc(info.file, "file") : (info.file ?? "");
                        saveDoc({...info, file: file, image: img});
                    }}/>
                </Div>]}} size={"full"} onClose={onClose} title={id === "new" ? "New product" : info?.title_en}>
                <Uploader
                    value={info?.image ? (typeof info?.image === "string" ? getImage(info?.image) : URL.createObjectURL(info.image)) : undefined}
                    onChange={(e) => setInfo(prev => {
                        return {...prev, image: e}
                    })}
                    size={"l"}
                />
                <Space/>
                <Text color={"grey-light"} size={"xsm"}>Category</Text>
                <Space size={"xsm"}/>
                <Select selected={info.category_id} onChange={(e) => setInfo(prev => {
                    return {...prev, category_id: e.id}
                })} search={true}
                        options={categories?.map(dis => {
                            return {title: dis.title_hy, id: dis.id}
                        })}/>

                <Space/>
                <Text color={"grey-light"} size={"xsm"}>Disciplines</Text>
                <Space size={"xsm"}/>
                <ListPicker items={disciplines} onChange={(e)=>setInfo(prev=>{
                    return {...prev, disciplines: e}
                })} value={info.disciplines}/>
                <Space/>
                <Text color={"grey-light"} size={"xsm"}>Videos</Text>
                <Space size={"xsm"}/>
                <ListPicker options={{viewImage:true}} items={videos?.map(x=>{return{...x, image: getVideoCover(x)}})} lang={"hy"} onChange={(e)=>setInfo(prev=>{
                    return {...prev, videos: e}
                })} value={info.videos}/>
                <Space/>
                <Text color={"grey-light"} size={"xsm"}>Topics</Text>
                <Space size={"xsm"}/>
                <ListPicker items={topics} lang={"hy"} onChange={(e)=>setInfo(prev=>{
                    return {...prev, topics: e}
                })} value={info.topics}/>
                <Space/>
                <Space/>
                <Input type={"number"} label={"Sort"} placeholder={"Sort"} value={info.sort ?? "0"}
                       onChange={(e) => setInfo(prev => {
                           return {...prev, sort: e}
                       })}/>
                <Space/>
                <LangTabs tabs={[

                    {
                        title: "Հայերեն",
                        content: <Div column>
                            <Input stretch={"full"} label={"Անվանում"} placeholder={"Անվանում"} value={info.title_hy}
                                   onChange={(e) => setInfo(prev => {
                                       return {...prev, title_hy: e}
                                   })}/>
                            <Space/>
                            <Text color={"grey-light"} size={"xsm"}>Նկարագրություն</Text>
                            <Space size={"xsm"}/>
                            <Editor key={"editor-hy"} value={info.text_hy} onChange={(e) => {
                                setInfo(prev => {
                                    return {...prev, text_hy: e}
                                });
                            }}/>
                        </Div>
                    },
                    {
                        title: "English",
                        content: <Div column>
                            <Input stretch={"full"} label={"Title"} placeholder={"Title"} value={info.title_en}
                                   onChange={(e) => setInfo(prev => {
                                       return {...prev, title_en: e}
                                   })}/>
                            <Space/>
                            <Text color={"grey-light"} size={"xsm"}>Description</Text>
                            <Space size={"xsm"}/>
                            <Editor key={"editor-en"} value={info.text_en} onChange={(e) => {
                                setInfo(prev => {
                                    return {...prev, text_en: e}
                                });
                            }}/>
                        </Div>
                    },
                    {
                        title: "Русский",
                        content: <Div column>
                            <Input stretch={"full"} label={"Название"} placeholder={"Название "}
                                   value={info.title_ru} onChange={(e) => setInfo(prev => {
                                return {...prev, title_ru: e}
                            })}/>
                            <Space/>
                            <Text color={"grey-light"} size={"xsm"}>Описание</Text>
                            <Space size={"xsm"}/>
                            <Editor key={"editor-ru"} value={info.text_ru} onChange={(e) => {
                                setInfo(prev => {
                                    return {...prev, text_ru: e}
                                });
                            }}/>
                        </Div>
                    }
                ]}/>
                <Space/>
                <Text color={"grey-light"} size={"xsm"}>Upload file</Text>
                <Space size={"xsm"}/>
                {typeof info?.file === "string" && info.file.length ? <Div column className={"width"}>
                    <Div alignH={"center"} gap={6}>
                        <Text>{info.file}</Text> <Button color={"red"} onClick={() => setInfo(prev => {
                        return {...prev, file: null}
                    })} iconLeft={<IoMdClose/>}/>
                    </Div></Div> : <input type={"file"} onChange={(e) => setInfo(prev => {
                    return {...prev, file: e.target.files[0]}
                })}/>}
            </Dialog>
            {remove && <Dialog
                size={"xsm"}
                title={"Delete"}
                onClose={() => setRemove(null)}
                buttons={{
                    right: <Div>
                        <Button title={"Cancel"} color={"transparent"} onClick={() => setRemove(null)}/>
                        <Button  title={"Delete"} color={"red"} onClick={() => {
                            conn("products/removeProduct", {id: info.id}).then(() => window.location.reload())
                        }}/>
                    </Div>
                }}
            >
                <Text>Do you want to delete this Product?</Text>
            </Dialog>}
        </>
    );
};

export default ProductEditPage;