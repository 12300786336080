import React, {useEffect, useState} from 'react';
import Div from "../components/Div/Div";
import Text from "../components/Text/Text";
import Space from "../components/Space/Space";
import Button from "./components/Button/Button";
import ListItem from "../components/ListItem/ListItem";
import {conn, uploadFile, uploads_url} from "../helpers/request/connect";
import Dialog from "./components/Dialog/Dialog";
import Uploader from "./components/Uploader/Uploader";
import {getImage, getVideoCover} from "../helpers";
import Input from "../components/Input/Input";
import Tabs from "./components/Tabs/Tabs";
import Editor from "./components/Editor/Editor";
import Preloader from "./components/Preloader/Preloader";
import LangTabs from "./components/LangTabs/LangTabs";
import ListPicker from "./components/ListPicker/ListPicker";
import {Select} from "./components/Select/Select";
import {IoMdClose} from "react-icons/io";

const Publications = () => {

    const [items, setItems] = useState([]);
    const [info, setInfo] = useState<any>({});
    const [editContext, setEditContext] = useState(false);
    const [remove, setRemove] = useState(null);
    const [loading, setLoading] = useState(true);
    const [topics, setTopics] = useState([]);
    const [disciplines, setDisciplines] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [journals, setJournals] = useState([]);

    const [addDialog, setAddDialog] = useState(null);
    const [addInfo, setAddInfo] = useState<any>({});

    useEffect(() => {
        conn("publications/get").then((res: any) => {
            if(res.response) setItems(res?.response);
        conn("topics/get").then((top:any)=>{
            if(top.response) setTopics(top.response);
        conn("disciplines/get").then((dis:any)=>{
            if(dis.response) setDisciplines(dis.response);
            conn("languages/get").then((dis:any)=>{
                if(dis.response) setLanguages(dis.response);
                conn("journals/get").then((dis:any)=>{
                    if(dis.response) setJournals(dis.response);
                    setLoading(false);
                });
            });
        });
        });
        })
    }, [])

    useEffect(()=>{
        if(info.id) conn("topics/connects", {id: info.id, type: "publications"}).then((res:any)=>{
            if(res.response) setInfo(prev=>{
                return {...prev, topics: res.response?.map(x=>x.topic_id).join(",")}
            });
        })
    }, [info?.id])

    const saveDoc = (data) => {
        conn("publications/save", data).then(() => {
            window.location.reload();
        });
    }

    return (
        <Div column>
            <Preloader active={loading}/>
            <Space/>
            <Text size={"lm"} color={"main"}>Publications</Text>
            <Space/>
            <Button onClick={() => setEditContext(true)} stretch={"fixed"} title={"Add new"} color={"green"}/>
            <Space/>
            <Div column>
                {items.map(item => {
                    return <ListItem
                        key={"slide-" + item.id}
                        hover
                        onClick={() => {
                            setInfo(item);
                            setEditContext(true);
                        }}
                        title={<Div gap={4}>{item.sort} {item.title_hy?.length ? item.title_hy : "Untitled"}
                        </Div>}/>
                })}
            </Div>

            {/*///////////////////// edit /////////////////////*/}

            {editContext && <Dialog
                size={"full"}
                title={"Publication"}
                onClose={() => {
                    setInfo({});
                    setEditContext(false);
                }}
            >
                <Uploader
                    value={info.image ? (typeof info.image === "string" ? getImage(info.image) : URL.createObjectURL(info.image)) : undefined}
                    onChange={(e) => setInfo(prev => {
                        return {...prev, image: e}
                    })}
                    size={"ml"}
                />
                <Space/>
                <Input label={"Link"} placeholder={"Link"} value={info.link}
                       onChange={(e) => setInfo(prev => {
                           return {...prev, link: e}
                       })}/>
                <Space/>
                <Input label={"Year"} placeholder={"Year"} value={info.year}
                       onChange={(e) => setInfo(prev => {
                           return {...prev, year: e}
                       })}/>
                <Space/>

                <Text color={"grey-light"} size={"xsm"}>Discipline</Text>
                <Space size={"xsm"}/>
                <Select selected={info.discipline_id} onChange={(e)=>setInfo(prev=>{return{...prev, discipline_id: e.id}})} search={true} options={disciplines?.map(dis=>{return {title: dis.title_hy, id: dis.id}})}/>
                <Space/>

                <Text color={"grey-light"} size={"xsm"}>Language</Text>
                <Space size={"xsm"}/>
                <Select selected={info.language_id} onChange={(e)=> {
                    if(e.id === "new"){
                        setAddInfo({});
                        setAddDialog("language");
                    }else {
                        setInfo(prev => {
                            return {...prev, language_id: e.id}
                        });
                    }

                }} search={true} options={[...languages?.map(dis=>{return {title: dis.title, id: dis.id}}), {title: "Add new language", id: "new"}]}/>
                <Space/>
                <Text color={"grey-light"} size={"xsm"}>Journals</Text>
                <Space size={"xsm"}/>
                <Select selected={info.journal_id} onChange={(e)=> {
                    if(e.id === "new"){
                        setAddInfo({});
                        setAddDialog("journals");
                    }else {
                        setInfo(prev => {
                            return {...prev, journal_id: e.id}
                        });
                    }

                }} search={true} options={[...journals?.map(dis=>{return {title: dis.title, id: dis.id}}), {title: "Add new Journal", id: "new"}]}/>
                <Space/>
                <LangTabs tabs={[

                    {
                        title: "Հայերեն",
                        content: <Div column>
                            <Input stretch={"full"} label={"Անվանում"} placeholder={"Անվանում"} value={info.title_hy}
                                   onChange={(e) => setInfo(prev => {
                                       return {...prev, title_hy: e}
                                   })}/>
                        </Div>
                    },
                    {
                        title: "English",
                        content: <Div column>
                            <Input stretch={"full"} label={"Title"} placeholder={"Title"} value={info.title_en}
                                   onChange={(e) => setInfo(prev => {
                                       return {...prev, title_en: e}
                                   })}/>
                        </Div>
                    },
                    {
                        title: "Русский",
                        content: <Div column>
                            <Input stretch={"full"} label={"Название"} placeholder={"Название "}
                                   value={info.title_ru} onChange={(e) => setInfo(prev => {
                                return {...prev, title_ru: e}
                            })}/></Div>
                    }
                ]}/>

                <Space/>
                <Text color={"grey-light"} size={"xsm"}>Topics</Text>
                <Space size={"xsm"}/>
                <ListPicker items={topics} lang={"hy"} onChange={(e)=>setInfo(prev=>{
                    return {...prev, topics: e}
                })} value={info.topics}/>
                <Space/>
                <Div gap={12} alignV={"end"}>
                    <Button title={"Cancel"} onClick={() => {
                        setInfo({});
                        setEditContext(false);
                    }} color={"transparent"}/>
                    <Button stretch={"fixed"} title={"Save"} onClick={() => {
                        if(!info.language_id){
                            alert("Select a language or create new");
                        }else if(!info.journal_id){
                            alert("Select a Journal or create new");
                        }else if(!info.title_hy?.length){
                            alert("Fill in required fields");
                        }else{
                            setLoading(true);
                            if(typeof info.image === "string" || !info.image){
                                saveDoc(info);
                            }else{
                                uploadFile(info.image).then((res:any)=>{
                                    if(res.response) saveDoc({...info, image: res.response});
                                    else {
                                        alert("Error image uploading");
                                        setLoading(false);
                                    }

                                });
                            }

                        }
                    }} color={"green"}/>
                    {info.id ? <Button title={"Delete"} onClick={() => setRemove(info.id)} color={"red"}/> : null}
                </Div>
            </Dialog>}

            {/*///////////////////// remove /////////////////////*/}

            {remove && <Dialog
                size={"xsm"}
                title={"Delete"}
                onClose={() => setRemove(null)}
                buttons={{
                    right: <Div>
                        <Button title={"Cancel"} color={"transparent"} onClick={() => setRemove(null)}/>
                        <Button title={"Delete"} color={"red"} onClick={() => {
                            conn("publications/remove", {id: info.id}).then(() => window.location.reload())
                        }}/>
                    </Div>
                }}
            >
                <Text>Do you want to delete this?</Text>
            </Dialog>}

            {addDialog === "language" &&
                <Dialog
                    title={"New language"}
                    onClose={()=>setAddDialog(null)}
                    size={"xsm"}
                    buttons={{right: <Div gap={12}>
                            <Button title={"Cancel"} color={"grey"} onClick={()=>setAddDialog(null)}/>
                            <Button title={"Add"} stretch={"fixed"} color={"green"} onClick={()=>{
                                if(addInfo.title?.length){
                                    conn("languages/save", addInfo).then(()=>{
                                        conn("languages/get").then((res:any)=>setLanguages(res.response));
                                        setAddInfo({});
                                        setAddDialog(null);
                                    })
                                }else{
                                    alert("Fill in required fields");
                                }
                            }}/>
                        </Div>}}
                >
                    <Input type={"number"} label={"Sort"} placeholder={"Sort"} value={addInfo.sort ?? "0"}
                           onChange={(e) => setAddInfo(prev => {
                               return {...prev, sort: e}
                           })}/>
                    <Space/>
                    <Input stretch={"full"} label={"Title"} placeholder={"Title"} value={addInfo.title}
                           onChange={(e) => setAddInfo(prev => {
                               return {...prev, title: e}
                           })}/>
            </Dialog>
                || addDialog === "journals" &&
                <Dialog
                    title={"New Journal"}
                    onClose={()=>setAddDialog(null)}
                    size={"xsm"}
                    buttons={{right: <Div gap={12}>
                            <Button title={"Cancel"} color={"grey"} onClick={()=>setAddDialog(null)}/>
                            <Button title={"Add"} stretch={"fixed"} color={"green"} onClick={()=>{
                                if(addInfo.title?.length){
                                    conn("journals/save", addInfo).then(()=>{
                                        conn("journals/get").then((res:any)=>setJournals(res.response));
                                        setAddInfo({});
                                        setAddDialog(null);
                                    })
                                }else{
                                    alert("Fill in required fields");
                                }
                            }}/>
                        </Div>}}
                >
                    <Input type={"number"} label={"Sort"} placeholder={"Sort"} value={addInfo.sort ?? "0"}
                           onChange={(e) => setAddInfo(prev => {
                               return {...prev, sort: e}
                           })}/>
                    <Space/>
                    <Input stretch={"full"} label={"Title"} placeholder={"Title"} value={addInfo.title}
                           onChange={(e) => setAddInfo(prev => {
                               return {...prev, title: e}
                           })}/>
            </Dialog>}

        </Div>
    );
};

export default Publications;
