import axios from "axios";
import {Cookies} from "react-cookie";
import {cookies} from "../index";

export const base_url = "https://medstandard.am/api";
export const uploads_url = "https://medstandard.am/api/uploads";

export const conn = (request: string, data: any = {}, onProgress?: (e: { loaded: number, total: number })=>void)=>new Promise((resolve)=>{

    const headers: any = {
        lang: cookies.get("lang") ?? "hy",
    }
    if(cookies.get("ad_token") && cookies.get("ad_id")) {
        headers.id = cookies.get("ad_id");
        headers.token = cookies.get("ad_token");
    }
    axios.post(`${base_url}/${request}`, {data}, {
        headers: headers,
        method: 'post',
        onUploadProgress: function(progressEvent:any) {
            if(onProgress) onProgress({loaded: progressEvent.loaded, total: progressEvent.total});
        }
    }).then(res=>{
        if(res && res.data) {
            resolve(res.data);
        };
    });
});

export const uploadFile = (file: any, type = "simple", watermark?: boolean, onProgress?: (e: { loaded: number, total: number })=>void)=>new Promise((resolve)=>{

    const cookies = new Cookies();
    const headers: any = {
        lang: localStorage.getItem("lang") ?? "en",
        'Content-Type': 'multipart/form-data',
    }
    if(cookies.get("ad_id") && cookies.get("ad_token")) {
        headers.id = cookies.get("ad_id");
        headers.token = cookies.get("ad_token");
    }



    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload-type", type);
    formData.append("watermark", watermark? "1": "0");
    axios.post(base_url + '/upload/', formData, {
        headers: headers,
        onUploadProgress: function(progressEvent:any) {
            if(onProgress) onProgress({loaded: progressEvent.loaded, total: progressEvent.total});
        }
    }).then((res:any)=>{
        if(res && res.data) resolve(res.data);
    });
});