import * as React from 'react';
import classNames from "classnames";
import './Text.scss';
const Text = (props:{
    children: any,
    size?: "sm" | "xsm" | "xmd" | "md" |"lm" |"lg",
    weight?: "bold" | "medium",
    singleLine?: boolean,
    color?:
        | "light"
        | "dark"
        | "grey-light"
        | "grey-middle"
        | "main"
        | "red"
        | "grey",
    align?: "center" | "right",
    lineThrough?: boolean,
    padding?: "xsm" | "sm" | "md",
    wrap?: "pre" | "unset",
    className?: string,
    uppercase?: boolean,
    linesCount?: 2 | 3 | 4 | 6 | 10
}) => {
    const {
        children,
        size = "",
        weight,
        singleLine,
        color = "dark",
        align,
        lineThrough,
        padding = "default",
        wrap = "unset",
        className = "",
        uppercase,
        linesCount = 0
    } = props;

    const textClass = classNames("text", className, size, color, align, {
        "m": weight === "medium",
        "b": weight === "bold",
        [`padding-${padding}`]: true,
        [`wrap-${wrap}`]: true,
        "line-through": lineThrough,
        'single-line': singleLine,
        'uppercase': uppercase,
        [`lines-count lines-count-${linesCount}`]: linesCount,
    })

    return <span className={textClass}>{children}</span>
};
export default Text;