import React from 'react';
import "./Preloader.scss";
import PlusButton from "../../../components/PlusButton/PlusButton";
import {Simulate} from "react-dom/test-utils";
import progress = Simulate.progress;
const Preloader = (props:{active?: boolean, progress?: number}) => {
    return (<>{props.active? <div className={"preloader"}>
            <PlusButton loading={true} title={""}/>
                {props.progress ? <div className={"loader-progress-bar-content"}>
                    <div className={"loader-progress-bar"} style={{width: props.progress + "%"}}></div>
                </div> : null}
            </div>
            : <></>}</>
    );
};

export default Preloader;
