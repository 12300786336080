import React, {useEffect, useMemo, useState} from 'react';

import {Link} from "react-router-dom";
import "./Header.scss";
import {RxExit} from "react-icons/rx";
import {useParams} from "react-router";
import {GiHamburgerMenu} from "react-icons/gi";
import Div from "../../../components/Div/Div";
import Space from "../../../components/Space/Space";
import Text from "../../../components/Text/Text";
import {cookies} from "../../../helpers";
import Button from "../Button/Button";

const Header = () => {
    const params = useParams();

    const [counts, setCounts] = useState({
        donate: 0,
        contacts: 0
    });

    const closeMenu = (e)=>{
      if(!e.target.closest(".menu-button")) setOpened(false);
    }

    useEffect(()=>{
        document.addEventListener("click", closeMenu);

        return ()=> document.removeEventListener("click", closeMenu);

    }, [])

    const [opened, setOpened] = useState(false);

    const menu = useMemo(()=>{
        return [
            {title: "Main", url: "/admin"},
            {title: "Slides", url: "/admin/slides"},
            {title: "Topics", url: "/admin/topics"},
            {title: "Disciplines", url: "/admin/disciplines"},
            {title: "Products categories", url: "/admin/products-categories"},
            {title: "Products", url: "/admin/products"},
            {title: "Catalogs", url: "/admin/catalogs"},
            {title: "Videos", url: "/admin/videos"},
            {title: "Publications", url: "/admin/publications"},
            {title: "Events", url: "/admin/events"},
            {title: "Services", url: "/admin/services"},
            {title: "Blog", url: "/admin/blog"},
            {title: "Journals", url: "/admin/journals"},
            {title: "Languages", url: "/admin/languages"},
            {title: "Partners", url: "/admin/partners"},
        ]
    }, []);


    return (
        <Div column className={"admin-header"} alignH={"center"}>

            <Div className={"header-in"}>
            <Space size={"sm"}/>
                <Button className={"menu-button"} iconLeft={<GiHamburgerMenu/>} onClick={()=>setOpened(!opened)}/>
            <Div className={"menu-content" + (opened? " opened": "")} gap={12} wrap={true} column={true}>
                {menu.map((v, i)=>{
                    return <div key={"menu-"+i}>
                        <Link to={v.url}><Text color={"/" + params.p1 === v.url || !params.p1 && v.url === "/"? "main": "light"} singleLine={true}>{v.title}</Text></Link>
                    </div>
                })}
                <Space size={"sm"} vertical={false}/>
                <Space vertical={false} size={"auto"}/>
            <Button onClick={()=>{
                cookies.set("ad_token", "");
                cookies.set("ad_id", "");
                window.location.reload();
            }} title={<Text color={"light"}>Exit</Text>} color={"transparent"} iconRight={<RxExit/>}/>
            </Div>
            <Space size={"sm"}/>
            </Div>
        </Div>
    );
};

export default Header;
