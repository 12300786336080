import * as React from 'react';
import {Portal} from "react-portal";
import "./Dialog.scss"
import Button from "../Button/Button";
import {IoMdClose} from "react-icons/io";
import classNames from "classnames";
import {useEffect, useRef} from "react";
import Text from "../../../components/Text/Text";
import Space from "../../../components/Space/Space";
import ScrollBar from "../../../components/ScrollBar/ScrollBar";



const Dialog = (props: {
    title: string,
    onClose: ()=>void,
    size?: "xsm" | "sm" | "md" | "full",
    closeOnCLickOut?: boolean,
    children: JSX.Element | JSX.Element[],
    contentCenter?: boolean,
    scroll?: boolean,
    dialogCenter?: boolean,
    className?: string,
    autoFocus? :boolean,
    buttons?: {
        right?: JSX.Element | JSX.Element[],
        left?: JSX.Element | JSX.Element[],
    },
    progress?: {
        loaded: number,
        total: number,
    }
})=>{

    const {title, onClose, closeOnCLickOut, buttons, children, contentCenter, dialogCenter = true, className = "", progress, size = "sm", scroll = true, autoFocus = true} = props;

    const dialogRef: any = useRef();

    const classes = classNames("dialog", className, {
        "content-center": contentCenter,
        "no-scroll": !scroll,
        [`size-${size}`]: true,
    });

    useEffect(()=>{
        if(dialogRef?.current && autoFocus){
            setTimeout(()=>{
                dialogRef?.current?.querySelector("input[type='text']")?.focus();
            }, 100)

        }
    }, [dialogRef, autoFocus])

    const content = <div className={classNames("dialog-cover", {"center-dialog": dialogCenter})} onClick={closeOnCLickOut? (e)=>{
        // @ts-ignore
        if(!e.target?.closest(".dialog")) onClose();
    }: undefined}>
        <div className={classes} ref={dialogRef}>
            <div className={"dialog-header"}>
                <Text singleLine weight={"medium"} size={"xmd"}>{title}</Text>
                <Button hasHover onClick={onClose} color={"transparent"} iconLeft={<IoMdClose size={20}/>}/>
            </div>
            <div className={"dialog-content scrollbar"}>{scroll? <ScrollBar>{children}</ScrollBar>: children}</div>
            {buttons && <>
                <Space size={"md"} vertical/>
                <div className={"dialog-buttons"}>
                {buttons.left && <div key={"dialog-l-b"} className={"dialog-l-buttons"}>{buttons.left}</div>}
                {buttons.right && <div key={"dialog-r-b"} className={"dialog-r-buttons"}>{buttons.right}</div>}
            </div></>}
            {progress && progress.loaded < progress.total && <div className="dialog-progress-cover">
                <div className="dialog-progress-bar"
                     style={{width: progress.loaded / progress.total * 100 + "%"}}></div>
            </div>}
        </div>

    </div>


    return (<Portal node={document && document.getElementById('dialog-content')}>
        {content}
    </Portal>);
}
export default Dialog;