import React, {ReactElement, useRef} from 'react';
import "./SimpleScroller.scss";
import Div from "../Div/Div";
import {AiOutlineLeft, AiOutlineRight} from "react-icons/ai";

const SimpleScroller = (props: {
    items?: ReactElement[] | ReactElement
}) => {

    const {items} = props;
    const ref = useRef();


    return (
        <Div className={"video-scroller"} column>
            <Div className={"video-scroller-content width"} alignH={"center"}>
                <Div className={"video-scroller-button"} alignH={"center"} onClick={()=>{
                    // @ts-ignore
                    const scrolled = ref.current.scrollLeft
                    // @ts-ignore
                    const width = ref.current.clientWidth;
                    // @ts-ignore
                    ref?.current.scrollTo({left: scrolled - width / 2, behavior: "smooth" });
                }}><AiOutlineLeft/></Div>
                <div className={"video-scroller-in"} ref={ref}>
                    <Div className={"video-scroller-scroll"}>
                        {items}
                    </Div>
                </div>
                <Div className={"video-scroller-button"} alignH={"center"} onClick={()=>{
                    // @ts-ignore
                    const scrolled = ref.current.scrollLeft
                    // @ts-ignore
                    const width = ref.current.clientWidth
                    // @ts-ignore
                    ref?.current.scrollTo({left: scrolled + width / 2, behavior: "smooth" });
                }}><AiOutlineRight/></Div>
            </Div>
        </Div>
    );
};

export default SimpleScroller;
