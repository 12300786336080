import React, {useEffect, useState} from 'react';
import {useParams} from "react-router";
import HomePage from "./HomePage/HomePage";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import DisciplinePage from "./DisciplinePage/DisciplinePage";
import {cookies} from "../helpers";
import ServicesPage from "./ServicesPage/ServicesPage";
import MediaLibraryPage from "./MediaLibraryPage/MediaLibraryPage";
import EventsPage from "./EventsPage";
import BlogPage from "./ServicesPage/BolgsPage";
import {lang} from "../helpers/lang/lang";
import ProjectsPage from "./DisciplinePage/ProjectsPage";
import ProductCategoryPage from "./DisciplinePage/ProductCategoryPage";
import ProductPage from "./DisciplinePage/ProductPage";

const lang_arr = ["hy", "en", "ru"]

const MainPage = () => {
    const params = useParams();
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        setLoading(false);
        window.scrollTo({top: 0});
        if(params.p1 === "services"){
            document.title = lang["services"] + " - " + lang["medstandard"];
        }else if(params.p1 === "discipline"){
            document.title = lang["disciplines"] + " - " + lang["medstandard"];
        }else if(params.p1 === "projects"){
            document.title = lang["projects"] + " - " + lang["medstandard"];
        }else if(params.p1 === "blog"){
            document.title = lang["blog"] + " - " + lang["medstandard"];
        }else if(params.p1 === "events"){
            document.title = lang["events"] + " - " + lang["medstandard"];
        }else if(params.p1 === "media-library"){
            if(params.p2 === "videos"){
                document.title = lang["videos"] + " - " + lang["medstandard"];

            }else if(params.p2 === "publications"){
                document.title = lang["publications"] + " - " + lang["medstandard"];

            }else if(params.p2 === "catalogs"){
                document.title = lang["brochures-catalogs-flyers"] + " - " + lang["medstandard"];

            }else{
                document.title = lang["media-library"] + " - " + lang["medstandard"];

            }
        }else{
            document.title = lang["medstandard"];
        }
    }, [params])
    return (
        <div className={"main-content"}>
            {loading? "Loading" : <>
                <Header/>
                {params.p1?.toLowerCase() === "discipline" && <DisciplinePage/>
                || params.p1?.toLowerCase() === "product-category" && <ProductCategoryPage/>
                || params.p1?.toLowerCase() === "products" && <ProductPage/>
                || params.p1?.toLowerCase() === "services" && <ServicesPage/>
                || params.p1?.toLowerCase() === "media-library" && <MediaLibraryPage/>
                || params.p1?.toLowerCase() === "events" && <EventsPage/>
                || params.p1?.toLowerCase() === "blog" && <BlogPage/>
                    || <HomePage/>}
                <Footer/>
            </>}

        </div>
    );
};

export default MainPage;
