import React, {useEffect, useMemo, useState} from 'react';
import "./ServicesPage.scss";
import Div from "../../components/Div/Div";
import MainSlider from "../../components/MainSlider/MainSlider";
import Space from "../../components/Space/Space";
import PageTitle from "../../components/PageTitle/PageTitle";
import {lang, lg} from "../../helpers/lang/lang";
import Text from "../../components/Text/Text";
import GradientBG from "../../components/GradientBG/GradientBG";
import {Link} from "react-router-dom";
import ProductItem from "../../components/ProductItem/ProductItem";
import PlusButton from "../../components/PlusButton/PlusButton";
import {useParams} from "react-router";
import Preloader from "../../admin/components/Preloader/Preloader";
import {conn} from "../../helpers/request/connect";
import {getImage, langChoose} from "../../helpers";
import EditorTextViewer from "../../components/EditorTextViewer/EditorTextViewer";



const BlogPage = () => {

    const params = useParams();

    const [loading, setLoading] = useState(true);
    const [catalogs, setCatalogs] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [filter, setFiler] = useState<any>({});
    const [data, setData] = useState<any>({});
    const [pageLoading, setPageLoading] = useState(false);
    const [blog, setBlog] = useState<any>({});

    useEffect(()=>{
        if(page){
            setLoading(true);
            conn("web/getNews", {id: params.p2, page: page, limit: 12}).then((res:any)=>{
                if(res.response) setCatalogs(page === 1? res.response: prev=>[...prev, ...res.response]);
                if(res.count || res.count === 0) setCount(res.count);
                if(res.blog) setBlog(res.blog);
                setLoading(false);
                setPageLoading(false);
            });
        }
    }, [page, filter, params?.p2]);

    useEffect(()=>{
       if(!params.p2) setPage(1);
    }, [params?.p2])

    return (
        <Div column className={"services-content"}>
            <Preloader active={loading}/>
            {params.p2 ? <Div className={"width"} column>
                <Space size={"md"}/>
                <Div className={"service-item"}>
                    {blog?.image ?<Div className={"service-image"}>
                        <img src={getImage(blog?.image)}/>
                    </Div>: null}
                    <Div className={"service-text-content"} column>
                        <Text size={"lm"}>{langChoose(blog, "title_", lg)}</Text>
                        <Space size={"md"}/>
                        <EditorTextViewer text={langChoose(blog, "text_", lg)}/>
                    </Div>
                </Div>
                <Space/>
            </Div> :  <Div column className={"width"}>
                <Space size={"xlg"}/>
                <Text size={"lg"} color={"main"}>{lang["blog"]}</Text>
                <Space/>
                <GradientBG><>
                <Div column width={"full"}>
                    <Div wrap={true} className={"product-items-content"}>
                        {catalogs.map(pr => {
                            return <Link to={"/" + lg + "/blog/" + pr.id} key={"pr-item" + pr.id}><ProductItem
                                product={pr}/></Link>
                        })}
                    </Div>

                </Div>
                <Space/>
                {count > catalogs?.length || pageLoading ? <Div alignV={"center"}>
                    <PlusButton loading={pageLoading} onCLick={()=> {
                        setPage(prev => prev + 1);
                        setPageLoading(true);
                    }} title={"See more"}/>
                </Div>: null}
            </>
            </GradientBG></Div>}



        </Div>
    );
};

export default BlogPage;
