import React, {useEffect, useState} from 'react';
import Div from "../components/Div/Div";
import Text from "../components/Text/Text";
import Space from "../components/Space/Space";
import Button from "./components/Button/Button";
import ListItem from "../components/ListItem/ListItem";
import {conn, uploadFile} from "../helpers/request/connect";
import Dialog from "./components/Dialog/Dialog";
import Uploader from "./components/Uploader/Uploader";
import {dateToday, getDate, getImage} from "../helpers";
import Input from "../components/Input/Input";
import Tabs from "./components/Tabs/Tabs";
import Editor from "./components/Editor/Editor";
import Preloader from "./components/Preloader/Preloader";
import LangTabs from "./components/LangTabs/LangTabs";
import ListPicker from "./components/ListPicker/ListPicker";
import {Select} from "./components/Select/Select";

const Partners = () => {

    const [items, setItems] = useState([]);
    const [info, setInfo] = useState<any>({});
    const [editContext, setEditContext] = useState(false);
    const [remove, setRemove] = useState(null);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        conn("partners/get").then((res: any) => {
            if (res.response) setItems(res?.response);
            setLoading(false);
        })
    }, [])

    const saveDoc = (data) => {
        conn("partners/save", data).then((res) => {
            window.location.reload();
        });
    }


    return (
        <Div column>
            <Preloader active={loading}/>
            <Space/>
            <Text size={"lm"} color={"main"}>Partners</Text>
            <Space/>
            <Button onClick={() => setEditContext(true)} stretch={"fixed"} title={"Add new"} color={"green"}/>
            <Space/>
            <Div column>
                {items.map(item => {
                    return <ListItem
                        key={"slide-" + item.id}
                        hover
                        onClick={() => {
                            setInfo(item);
                            setEditContext(true);
                        }}
                        title={<Div gap={4}>{item.sort} {item.title?.length ? item.title : "Untitled"}
                        </Div>}/>
                })}
            </Div>

            {/*///////////////////// edit /////////////////////*/}

            {editContext && <Dialog
                size={"sm"}
                title={"Partner"}
                onClose={() => {
                    setInfo({});
                    setEditContext(false);
                }}
            >
                <Uploader
                    value={info.image ? (typeof info.image === "string" ? getImage(info.image) : URL.createObjectURL(info.image)) : undefined}
                    onChange={(e) => setInfo(prev => {
                        return {...prev, image: e}
                    })}
                    size={"s"}
                />
                <Space/>
                <Input type={"number"} label={"Sort"} placeholder={"Sort"} value={info.sort ?? "0"}
                       onChange={(e) => setInfo(prev => {
                           return {...prev, sort: e}
                       })}/>
                <Space/>
                <Input stretch={"full"} label={"Title"} placeholder={"Title"} value={info.title}
                       onChange={(e) => setInfo(prev => {
                           return {...prev, title: e}
                       })}/>
                <Space/>
                <Input stretch={"full"} label={"Link"} placeholder={"Link"} value={info.link}
                       onChange={(e) => setInfo(prev => {
                           return {...prev, link: e}
                       })}/>
                <Space/>
                <Div gap={12} alignV={"end"}>
                    <Button title={"Cancel"} onClick={() => {
                        setInfo({});
                        setEditContext(false);
                    }} color={"transparent"}/>
                    <Button stretch={"fixed"} title={"Save"} onClick={() => {
                        if (!info.image) {
                            alert("Select a partner logo");
                        } else {
                            setLoading(true);
                            if(typeof info.image === "string"){
                                saveDoc(info);
                            }else{
                                uploadFile(info.image).then((res:any)=>{
                                    if(res.response) saveDoc({...info, image: res.response});
                                    else alert("Image upload error");
                                });
                            }
                        }
                    }} color={"green"}/>
                    {info.id ? <Button title={"Delete"} onClick={() => setRemove(info.id)} color={"red"}/> : null}
                </Div>
            </Dialog>}

            {/*///////////////////// remove /////////////////////*/}

            {remove && <Dialog
                size={"xsm"}
                title={"Delete"}
                onClose={() => setRemove(null)}
                buttons={{
                    right: <Div>
                        <Button title={"Cancel"} color={"transparent"} onClick={() => setRemove(null)}/>
                        <Button title={"Delete"} color={"red"} onClick={() => {
                            conn("partners/remove", {id: info.id}).then(() => window.location.reload())
                        }}/>
                    </Div>
                }}
            >
                <Text>Do you want to delete this?</Text>
            </Dialog>}
        </Div>
    );
};

export default Partners;
