import React, {useEffect, useMemo, useRef, useState} from 'react';
import "./MainMediaScroller.scss";
import Div from "../Div/Div";
import {BsPlayCircle} from "react-icons/bs";
import {AiOutlineLeft, AiOutlineRight} from "react-icons/ai";
import {getVideoCover} from "../../helpers";


const MainMediaScroller = (props: {media: any[], onSelect?: (e)=>void}) => {

    const {media, onSelect} = props;

    const [selected, setSelected] = useState(media[0].id);

    const selectedItem = useMemo(()=>{
        return media?.find(x => x.id === selected);
    }, [selected])

    return (
        <Div className={"main-media-library"} alignH={"center"} alignV={"center"}>
            <img className={"media-library-bg"} src={getVideoCover(selectedItem)} onClick={()=>onSelect(selectedItem)}/>
            <Div className={"media-library-play-button"} onClick={()=>onSelect(selectedItem)}><BsPlayCircle/></Div>
            <Div className={"media-library-control-content"} >
                <Div alignH={"center"} className={"media-library-control-button right"} onClick={()=>{
                 const scrollContent = document.getElementById("media-library-control-content");
                 scrollContent.scrollTo({
                    left: scrollContent.scrollLeft + scrollContent.clientWidth - 20,
                     behavior: "smooth"
                 });
                }}>
                    <AiOutlineRight/>
                </Div>
                <Div alignH={"center"} alignV={"end"} className={"media-library-control-button"} onClick={()=>{
                    const scrollContent = document.getElementById("media-library-control-content");
                    scrollContent.scrollTo({
                        left: scrollContent.scrollLeft - scrollContent.clientWidth - 20,
                        behavior: "smooth"
                    });
                }}>
                    <AiOutlineLeft/>
                </Div>
                <Div className={"media-library-control-content-in"} id={"media-library-control-content"}>
                {media.map((md, i)=>{
                    return <Div key={"media-lib-item-" + i} alignV={"center"} alignH={"center"} onClick={()=>setSelected(md.id)} className={`media-library-item${selectedItem.id === md.id? " selected": ""}`}>
                        <img src={getVideoCover(md)}/>
                        <BsPlayCircle/>
                    </Div>
                })}
                </Div>
            </Div>
        </Div>
    );
};

export default MainMediaScroller;
