import React, {useEffect, useState} from 'react';
import Div from "../components/Div/Div";
import Text from "../components/Text/Text";
import Space from "../components/Space/Space";
import Button from "./components/Button/Button";
import ListItem from "../components/ListItem/ListItem";
import {conn, uploadFile, uploads_url} from "../helpers/request/connect";
import Dialog from "./components/Dialog/Dialog";
import Uploader from "./components/Uploader/Uploader";
import {getImage} from "../helpers";
import Input from "../components/Input/Input";
import Tabs from "./components/Tabs/Tabs";
import Editor from "./components/Editor/Editor";
import Preloader from "./components/Preloader/Preloader";
import LangTabs from "./components/LangTabs/LangTabs";
import ListPicker from "./components/ListPicker/ListPicker";
import {BiCross} from "react-icons/bi";
import {IoMdClose} from "react-icons/io";

const Videos = () => {

    const [items, setItems] = useState([]);
    const [info, setInfo] = useState<any>({});
    const [editContext, setEditContext] = useState(false);
    const [remove, setRemove] = useState(null);
    const [loading, setLoading] = useState(true);
    const [progress, setProgress] = useState(0);


    useEffect(() => {
        conn("videos/get").then((res: any) => {
            if (res.response) setItems(res?.response);
            setLoading(false);
        })
    }, [])

    const saveDoc = (data) => {
        conn("videos/save", data).then(() => {
            window.location.reload();
        });
    }

    const uploadFileFunc = async (file, type)=>{
        if(typeof file === "string") return file;
        else {
            const res: any = await uploadFile(file, type, false, (e)=>type === "video" ?setProgress(e.loaded / (e.total / 100)): 0);
            if(res.response) return res.response;
            else {
                alert("File upload error");
                setLoading(false);
            }
        }
    }


    return (
        <Div column>
            <Preloader active={loading} progress={progress}/>
            <Space/>
            <Text size={"lm"} color={"main"}>Videos</Text>
            <Space/>
            <Button onClick={() => setEditContext(true)} stretch={"fixed"} title={"Add new"} color={"green"}/>
            <Space/>
            <Div column>
                {items.map(item => {
                    return <ListItem
                        key={"slide-" + item.id}
                        hover
                        onClick={() => {
                            setInfo(item);
                            setEditContext(true);
                        }}
                        title={<Div gap={4}>{item.sort} {item.title_hy?.length ? item.title_hy : "Untitled"}
                        </Div>}/>
                })}
            </Div>

            {/*///////////////////// edit /////////////////////*/}

            {editContext && <Dialog
                size={"full"}
                title={"Video"}
                onClose={() => {
                    setInfo({});
                    setEditContext(false);
                }}
            >
                {info?.link?.length ?
                    <iframe width="560" height="315" src={"https://www.youtube.com/embed/" + info.link}
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen></iframe> : <><Uploader
                        value={info.image ? (typeof info.image === "string" ? getImage(info.image) : URL.createObjectURL(info.image)) : undefined}
                        onChange={(e) => setInfo(prev => {
                            return {...prev, image: e}
                        })}
                        size={"ml"}
                    />
                        <Space/>
                        <Div column>
                            <Text color={"grey-light"} size={"xsm"}>Upload video</Text>
                            <Space size={"xsm"}/>
                            {typeof info?.file === "string"? <Div column className={"width"}>
                                <video controls controlsList={"nodownload"} src={uploads_url + "/videos/" + info.file}/>
                                <Space/>
                                <Div alignH={"center"} gap={6}>
                                <Text>{info.file}</Text> <Button color={"red"} onClick={()=>setInfo(prev=>{return{...prev, file: null}})} iconLeft={<IoMdClose/>}/>
                            </Div></Div> : <input type={"file"} accept={"video/*"} onChange={(e) => setInfo(prev => {
                                return {...prev, file: e.target.files[0]}
                            })}/>}
                        </Div>


                    </>}
                <Space/>
                <Input type={"number"} label={"Sort"} placeholder={"Sort"} value={info.sort ?? "0"}
                       onChange={(e) => setInfo(prev => {
                           return {...prev, sort: e}
                       })}/>
                <Space/>
                <Input label={"Youtube link"} placeholder={"Youtube link"} value={info.link}
                       onChange={(e) => setInfo(prev => {
                           return {...prev, link: e}
                       })}/>
                <Space/>

                <LangTabs tabs={[

                    {
                        title: "Հայերեն",
                        content: <Div column>
                            <Input stretch={"full"} label={"Անվանում"} placeholder={"Անվանում"} value={info.title_hy}
                                   onChange={(e) => setInfo(prev => {
                                       return {...prev, title_hy: e}
                                   })}/>
                        </Div>
                    },
                    {
                        title: "English",
                        content: <Div column>
                            <Input stretch={"full"} label={"Title"} placeholder={"Title"} value={info.title_en}
                                   onChange={(e) => setInfo(prev => {
                                       return {...prev, title_en: e}
                                   })}/>
                        </Div>
                    },
                    {
                        title: "Русский",
                        content: <Div column>
                            <Input stretch={"full"} label={"Название"} placeholder={"Название "}
                                   value={info.title_ru} onChange={(e) => setInfo(prev => {
                                return {...prev, title_ru: e}
                            })}/>
                        </Div>
                    }
                ]}/>

                <Space/>
                <Div gap={12} alignV={"end"}>
                    <Button title={"Cancel"} onClick={() => {
                        setInfo({});
                        setEditContext(false);
                    }} color={"transparent"}/>
                    <Button stretch={"fixed"} title={"Save"} onClick={() => {

                        if (!info.link && !info.file) {
                            alert("Select a video file or enter a YouTube link.");
                        } else if (!info.link && !info.image) {
                            alert("Select cover for video")
                        } else if (!info.title_hy?.length) {
                            alert("Fill in required fields");
                        } else {
                            setLoading(true);
                            if (info.link) {
                                saveDoc({...info, image: "", file: ""});
                            } else {
                                (async ()=>{
                                 const img = await uploadFileFunc(info.image, "simple");
                                 const video = await uploadFileFunc(info.file, "video");
                                 if(img && video) saveDoc({...info, file: video, image: img});
                                })();
                            }
                        }
                    }} color={"green"}/>
                    {info.id ? <Button title={"Delete"} onClick={() => setRemove(info.id)} color={"red"}/> : null}
                </Div>
            </Dialog>}

            {/*///////////////////// remove /////////////////////*/}

            {remove && <Dialog
                size={"xsm"}
                title={"Delete"}
                onClose={() => setRemove(null)}
                buttons={{
                    right: <Div>
                        <Button title={"Cancel"} color={"transparent"} onClick={() => setRemove(null)}/>
                        <Button title={"Delete"} color={"red"} onClick={() => {
                            conn("videos/remove", {id: info.id}).then(() => window.location.reload())
                        }}/>
                    </Div>
                }}
            >
                <Text>Do you want to delete this?</Text>
            </Dialog>}
        </Div>
    );
};

export default Videos;
