import React, {useEffect, useState} from 'react';
import Div from "../components/Div/Div";
import Space from "../components/Space/Space";
import Login from "./Login/Login";
import {conn} from "../helpers/request/connect";
import {cookies} from "../helpers";
import Text from "../components/Text/Text";
import ScrollBar from "../components/ScrollBar/ScrollBar";
import AdminMainPage from "./AdminMainPage/AdminMainPage";
import Header from "./components/Header/Header";

const Main = () => {

    const [state, setState] = useState("loading");

    useEffect(()=>{
        if(cookies.get("ad_id") && cookies.get("ad_token")){
            conn("").then((res: any)=>{
                if(res.auth === true){
                    setState("success");
                }else{
                    setState("login");
                }
            })
        }else{
         setState("login");
        }
    }, [])


    return (<Div column={true} alignH={"center"}>

                {state === "loading" && <Div alignV={"center"}>
                    <Space size={"sm"}/>
                    <Text color={"grey-light"}>Authorization</Text>
                </Div> || state === "login" && <Login/> || <>
                    <Header/><ScrollBar><AdminMainPage/></ScrollBar></>}
        </Div>
    );
};

export default Main;




