import React, {ReactElement} from 'react';
import "./GradientBG.scss";

const GradientBG = (props: { children: string | ReactElement | ReactElement[] }) => {
    return (
        <div className={"gradient-bg"}>
            <div className="gradient-bg-in">
                {props.children}
            </div>
        </div>
    );
};

export default GradientBG;
