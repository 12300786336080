import React, {useEffect, useMemo, useState} from 'react';
import "./Homepage.scss";
import MainSlider from "../../components/MainSlider/MainSlider";
import PageTitle from "../../components/PageTitle/PageTitle";
import Div from "../../components/Div/Div";
import GradientBG from "../../components/GradientBG/GradientBG";
import Space from "../../components/Space/Space";
import ProductItem from "../../components/ProductItem/ProductItem";
import {Link} from "react-router-dom";
import PlusButton from "../../components/PlusButton/PlusButton";
import Text from "../../components/Text/Text";
import MainMediaScroller from "../../components/MainMediaScroller/MainMediaScroller";
import PartnersScroll from "../../components/PartnersScroll/PartnersScroll";
import {useParams} from "react-router";
import {lang, lg} from "../../helpers/lang/lang";
import {conn, uploads_url} from "../../helpers/request/connect";
import Preloader from "../../admin/components/Preloader/Preloader";
import {getImage, langChoose, removeTags} from "../../helpers";
import VideoPlayer from "../../components/VideoPlayer/VideoPlayer";


const HomePage = () => {

    const [allDisciplines, setAllDisciplines] = useState(false);
    const [slides, setSlides] = useState([]);
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [blog, setBlog] = useState(null);
    const [videos, setVideos] = useState([]);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [partners, setPartners] = useState([]);

    const disciplines = useMemo(() => {
        return allDisciplines ? products : products.slice(0, 6);
    }, [products, allDisciplines])

    const params = useParams();

    useEffect(() => {
        conn("web/main-page").then((res: any) => {
            if (res.slides) setSlides(res.slides);
            if (res.dis) setProducts(res.dis);
            if (res.blog) setBlog(res.blog);
            if (res.videos) setVideos(res.videos);
            if (res.partners) setPartners(res.partners);


            setLoading(false);
        });
    }, [])


    return (
        <div id={"home-page"}>
            <Preloader active={loading}/>
            <MainSlider slides={slides.map(v => {
                return {
                    image: uploads_url + "/images/" + v.image,
                    text1: v["text_1_" + lg],
                    text2: v["text_2_" + lg],
                    text3: v["text_3_" + lg],
                }
            })}/>
            <Div column className={"width"}>
                <Space size={"lg"}/>
                <PageTitle upperCase={true}>{lang["disciplines"]}</PageTitle>
            </Div>
            <Space size={"md"}/>
            <GradientBG>
                <Div column width={"full"}>
                    <Div wrap={true} className={"product-items-content"}>
                        {disciplines?.length ? disciplines?.map(pr => {
                            return <Link to={"/" + lg + "/discipline/" + pr.id} key={"pr-item" + pr.id}><ProductItem
                                product={pr}/></Link>
                        }) : null}
                    </Div>

                </Div>
            </GradientBG>
            <Div width={"full"} alignV={"center"}>
                <PlusButton
                    state={!allDisciplines}
                    title={allDisciplines ? lang["see-less"] : lang["see-all"]}
                    onCLick={() => setAllDisciplines(!allDisciplines)}
                /></Div>
            <Div column className={"width"}>
                <Space size={"lg"}/>
                <PageTitle upperCase={true}>{lang["news"]}</PageTitle>
                <Space line={true}/>
                <Space/>
                <Div className={"main-news-content"}>
                    <Div className={"main-news-image"}>
                        <img src={getImage(blog?.image)}/>
                    </Div>
                    <Div className={"main-news-text"} column alignV={"center"}>
                        <Text color={"grey"}>{blog?.date?.replaceAll("-", "/")}</Text>
                        <Space size={"xsm"}/>
                        <Text linesCount={3} size={"md"} weight={"bold"}>{langChoose(blog, "title_", lg)}</Text>
                        <Space/>
                        <Text linesCount={6}>{langChoose(blog, "text_", lg, true)}</Text>
                        <Space size={"md"}/>
                       <Link to={"/" + lg + "/blog/" + blog?.id}> <PlusButton title={lang["read-more"]}/></Link>
                    </Div>
                </Div>
            </Div>
            {videos?.length ? <Div column className={"width"}>
                <Space size={"lg"}/>
                <PageTitle upperCase={true}>{lang["media-library"]}</PageTitle>
                <Space/>
                <Div className={"main-media-library"}>
                    <MainMediaScroller media={videos} onSelect={(e)=>setSelectedVideo(e)}/>
                </Div>
                <Space size={"md"}/>
                <Space size={"md"}/>
                <Div alignV={"center"}>
                    <Link to={"/" + lg + "/media-library/videos"}><PlusButton title={lang["see-all"]}/></Link></Div>
            </Div>: null}
            <Space size={"xlg"}/>
            <PartnersScroll partners={partners}/>
            <Space size={"md"}/>
            {selectedVideo ? <VideoPlayer video={selectedVideo} onClose={()=>setSelectedVideo(null)}/>: null}
        </div>
    );
};

export default HomePage;
