import React, {ReactElement} from 'react';
import "./PageTitle.scss";
import classNames from "classnames";
const PageTitle = (props: {children: string | ReactElement, upperCase?: boolean}) => {
    const {children, upperCase} = props;
    const classes = classNames("p-title", {
        uppercase: upperCase
    })
    return (
        <div className={classes}>
            {props.children}
        </div>
    );
};

export default PageTitle;
