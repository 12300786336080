import React, {useEffect, useRef, useState} from 'react';
import "./ProductItem.scss";
import {lang, lg} from "../../helpers/lang/lang";
import {getImage, langChoose} from "../../helpers";
import {Link} from "react-router-dom";


const ProductItem = (props: { product?: any }) => {
    const {product} = props;
    const [scroll, setScroll] = useState("none");
    const [scrolling, setScrolling] = useState(false);
    const [timer, setTimer] = useState(null);
    const ref = useRef();

    useEffect(() => {
        console.log(scroll);

        clearInterval(timer);

        if (scroll === "down") {
            setTimer(setInterval(() => {

                if (!scrolling) {
                    setScrolling(true);
                    // @ts-ignore
                    const items = ref?.current?.querySelectorAll(".product-item-list");
                    if (items.length > 5) {
                        items[0].style.marginTop = "-" + items[0].clientHeight + "px";
                        items[2].classList.remove("hovered");
                        items[3].classList.add("hovered");
                        setTimeout(() => {
                            // @ts-ignore
                            ref?.current?.querySelector(".product-item-list-bg-in")?.appendChild(items[0]);
                            items[0].style.marginTop = "0";
                            setScrolling(false);
                        }, 300);
                    } else {
                        clearInterval(timer);
                        //@ts-ignore
                        ref?.current?.classList.remove("scroll");
                    }
                }
            }, 0));
        } else {
            clearInterval(timer);
        }

    }, [scroll, scrolling]);

    const itemsScroll = () => {

    }

    return (
        <div className={"product-item"}>
            <div className="product-item-image">
                <img src={getImage(product?.image)}/>
                {product?.list?.length ? <div
                    className="product-item-list-bg"
                    ref={ref}
                    onMouseOut={(e) => {
                        // @ts-ignore
                        setScroll("none");
                    }}
                    onMouseOver={(e) => {

                        // @ts-ignore
                        const pos = e.target.closest(".product-item-list-bg")?.getBoundingClientRect();

                        if (e.clientY > pos.top + pos.height / 2 + 40) {
                            setScroll("down");
                        } else {
                            setScroll("none");

                        }
                    }
                    }>
                    <div className="product-item-list-bg-in"> {product?.list?.map((ls, i) => {
                        return <Link to={"/" + lg + "/projects/" + ls.id} key={"pr-item-l-" + i}><div
                                                  className={`product-item-list${i === 2 ? " hovered" : ""}`}>{langChoose(ls, "title_", lg)}</div></Link>
                    })}
                        <div className="product-item-list-more">{lang["see-all"]}</div>
                    </div>
                </div> : null}

            </div>
            <div className="product-item-title">{langChoose(product, "title_", lg)}</div>
            <div className="product-item-download">{product.download ? <>{lang["download"]}
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20 2C29.9412 2 38 10.0589 38 20C38 29.9412 29.9412 38 20 38"
                          stroke="url(#paint0_linear_486_202)" strokeWidth="3" strokeLinecap="round"/>
                    <path d="M20 38C10.0589 38 2 29.9412 2 20C2 10.0589 10.0589 2 20 2"
                          stroke="url(#paint1_linear_486_202)" strokeWidth="3" strokeLinecap="round"/>
                    <path d="M20 12V27" stroke="url(#paint2_linear_486_202)" strokeWidth="3" strokeLinecap="round"/>
                    <path d="M27 20L20 27L13 20" stroke="url(#paint3_linear_486_202)" strokeWidth="3"
                          strokeLinecap="round"/>
                    <defs>
                        <linearGradient id="paint0_linear_486_202" x1="29" y1="2" x2="29" y2="38"
                                        gradientUnits="userSpaceOnUse">
                            <stop offset="0.291667" stopColor="#009CBF"/>
                            <stop offset="0.958333" stopColor="#07EB82"/>
                        </linearGradient>
                        <linearGradient id="paint1_linear_486_202" x1="11" y1="2" x2="11" y2="38"
                                        gradientUnits="userSpaceOnUse">
                            <stop offset="0.291667" stopColor="#009CBF"/>
                            <stop offset="0.958333" stopColor="#07EB82"/>
                        </linearGradient>
                        <linearGradient id="paint2_linear_486_202" x1="20.5" y1="12" x2="20.5" y2="27"
                                        gradientUnits="userSpaceOnUse">
                            <stop offset="0.291667" stopColor="#009CBF"/>
                            <stop offset="0.958333" stopColor="#07EB82"/>
                        </linearGradient>
                        <linearGradient id="paint3_linear_486_202" x1="20" y1="20" x2="20" y2="27"
                                        gradientUnits="userSpaceOnUse">
                            <stop offset="0.291667" stopColor="#009CBF"/>
                            <stop offset="0.958333" stopColor="#07EB82"/>
                        </linearGradient>
                    </defs>
                </svg>
            </> : null}
            </div>

        </div>
    );
};

export default ProductItem;
