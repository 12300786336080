import React, {useEffect, useState} from 'react';
import Div from "../../components/Div/Div";
import Space from "../../components/Space/Space";
import Text from "../../components/Text/Text";
import {BsPlayCircle} from "react-icons/bs";
import PlusButton from "../../components/PlusButton/PlusButton";
import {conn} from "../../helpers/request/connect";
import {getVideoCover, langChoose} from "../../helpers";
import Preloader from "../../admin/components/Preloader/Preloader";
import VideoPlayer from "../../components/VideoPlayer/VideoPlayer";
import {lang, lg} from "../../helpers/lang/lang";

const VideoPage = () => {
    const [loading, setLoading] = useState(true);
    const [videos, setVideos] = useState([])
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [pageLoader, setPageLoader] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState(null);

    useEffect(()=>{
       if(page) conn("web/getVideos", {page: page}).then((res:any)=>{
          if(res.response) setVideos(page === 1? res.response: prev=>[...prev, ...res.response]);
          if(res.count) setCount(res.count);
          setLoading(false);
          setPageLoader(false);
        })
    }, [page]);


    return (
        <Div column>
            <Preloader active={loading}/>
            <Div className={"width"} column>
                <Space size={"xlg"}/>
                <Text size={"lg"} color={"main"}>{lang["videos"]}</Text>
                <Space size={"sm"}/>
                <Text uppercase size={"md"} color={"grey-light"}>{lang["all-our-videos-here"]}</Text>
            </Div>
            <Space/>
            <Div className={"media-library-page-content width"}>{videos.map(item=>{
                return <Div onClick={()=>setSelectedVideo(item)} key={"item-id-" + item.id} className={"media-library-item"}>
                    <img src={getVideoCover(item)}/>
                    <Div className={"media-library-item-play"}><BsPlayCircle/></Div>
                    <Div className={"media-library-item-title"}><Text size={"md"} align={"center"} color={"light"}>{langChoose(item, "title_", lg)}</Text></Div>
                </Div>
            })}</Div>
            <Space/>
            {count > videos?.length ? <Div alignV={"center"}>
                <PlusButton loading={pageLoader} onCLick={()=> {
                    setPageLoader(true);
                    setPage(prev => prev + 1);
                }} title={"See more"}/>
            </Div> : null}
            <Space/>
            {selectedVideo ? <VideoPlayer video={selectedVideo} onClose={()=>setSelectedVideo(null)}/>: null}
        </Div>
    );
};

export default VideoPage;
