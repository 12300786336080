import React, {useMemo, useState} from 'react';
import {useParams} from "react-router";
import ContextMenu from "../ContextMenu/ContextMenu";
import ListItem from "../ListItem/ListItem";
import "./LangSwitch.scss";
import {cookies} from "../../helpers";
import {lg} from "../../helpers/lang/lang";
const icons = {
    en: require("../../images/en.svg"),
    hy: require("../../images/hy.svg"),
    ru: require("../../images/ru.svg")
}

const langArr = [
    {title: "English", id: "en"},
    {title: "Հայերեն", id: "hy"},
    {title: "Русский", id: "ru"},
]

const LangSwitch = () => {
    const params = useParams();

    const [context, setContext] = useState(null);

    const curIcon = useMemo(()=>{
        return icons[lg]?.default;
    }, [lg]);


    return (
        <>
            <div className={"lang-button"} onClick={(e:any)=> {
                // @ts-config
                setContext(e.target.closest(".lang-button"))
            }}>
            <img src={curIcon}/>
        </div>
            {context && <ContextMenu
                size={"sm"}
                alignTo={context}
                onClose={()=>setContext(null)}
                content={langArr.filter(x => x.id !== lg).map(x=>{
                    return <ListItem
                        hover={true}
                        leftIcon={<img src={icons[x.id].default}/>}
                        onClick={()=>{
                            cookies.set("lang", x.id, 9999).then(()=>{
                                document.location.href = `/${x.id}${params.p1? "/" + params.p1: ""}${params.p2? "/" + params.p2: ""}${params.p3? "/" + params.p3: ""}`
                            });
                        }}
                        title={x.title}
                    />
                })}
            />}
        </>
    );
};

export default LangSwitch;
