import React, {useEffect, useState} from 'react';
import Div from "../components/Div/Div";
import Space from "../components/Space/Space";
import Text from "../components/Text/Text";
import PlusButton from "../components/PlusButton/PlusButton";
import {conn, uploads_url} from "../helpers/request/connect";
import GradientBG from "../components/GradientBG/GradientBG";
import FilterLine from "../components/FilterLine/FilterLine";
import {lang, lg} from "../helpers/lang/lang";
import {catalogTypes, getImage, getZero, langChoose} from "../helpers";
import {Link} from "react-router-dom";
import {BsPlayCircle} from "react-icons/bs";
import {FcDownload} from "react-icons/fc";
import {RiDownloadCloudFill} from "react-icons/ri";
import Preloader from "../admin/components/Preloader/Preloader";
import Table from "../components/Table/Table";
import MainSlider from "../components/MainSlider/MainSlider";

export default () => {

    const [loading, setLoading] = useState(true);
    const [catalogs, setCatalogs] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [filter, setFiler] = useState<any>({});
    const [data, setData] = useState<any>({});
    const [pageLoading, setPageLoading] = useState(false);
    const [cover, setCover] = useState(null);

    useEffect(()=>{
        conn("web/getFilterItems", {month: true}).then((res:any)=>setData(res));
    }, []);

    useEffect(()=>{
        if(page){
            conn("web/getEvents", {...(filter ?? {}), page: page, limit: 12}).then((res:any)=>{
                if(res.response) setCatalogs(page === 1? res.response: prev=>[...prev, ...res.response]);
                if(res.count || res.count === 0) setCount(res.count);
                if(res.cover) setCover(res.cover);
                setLoading(false);
                setPageLoading(false);
            });
        }
    }, [page, filter]);

    return (
        <Div column>
            <Preloader active={loading}/>
            {cover? <MainSlider slides={[{
                text1: lang["events"],
                text3: lang["services-slogan"],
                image: getImage(cover),
            }]}/> : null}
            <Div className={"width"} column>
                <Space size={"xlg"}/>
                <Text size={"lg"} color={"main"}>{lang["events"]}</Text>
                <Space/>
            </Div>
            <Space/>
            <Div className={"filter-content width"} column>
                <Space/>
                <FilterLine filters={[
                    {id: "discipline_id", title: lang["disciplines"], items: data?.disciplines?.map(item=>{return {id: item.id, title: langChoose(item, "title_", lg)}})},
                    {id: "topics", title: lang["topics"], items: data?.topics?.map(item=>{return {id: item.id, title: langChoose(item, "title_", lg)}})},
                    {id: "month", title: lang["month-event"], items: data?.month?.map(item=>{return {id: item.year + "-" + item.month, title:  item.year +" "+ lang["month"][item.month]}})},

                ]} onChange={(e)=>{
                    console.log(e);
                    setCatalogs([]);
                    setPageLoading(true);
                    setFiler(e);
                    setPage(0);
                    setTimeout(()=>setPage(1), 100);
                }}/>

            <Space/>
            <Space line={true}/>
                <Space/>
            <Table
            headers={[{title: lang["date"], id: "date", size: "m-column"}, {title: lang["title"], id: "title", size: "full-column"}]}
            list={catalogs?.map(pub=> {
                const date = new Date(pub.date);
                const is_over = date.getTime() + 24 * 3600 * 1000 < new Date().getTime();
                return {title: langChoose(pub, "title_", lg), date: date.getFullYear() + " " + lang["month"][date.getMonth() +1] + " " + getZero(date.getDate()), link: pub.link, is_over: is_over}
            })}
            />
                <Space size={"md"}/>


            <Space/>
            {count > catalogs?.length || pageLoading ? <Div alignV={"center"}>
                <PlusButton loading={pageLoading} onCLick={()=> {
                    setPage(prev => prev + 1);
                    setPageLoading(true);
                }} title={"See more"}/>
            </Div>: null}
            </Div>
            <Space/>
        </Div>
    );
};

