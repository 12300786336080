import React, {useState} from 'react';
import Div from "../../components/Div/Div";
import Input from "../../components/Input/Input";
import Space from "../../components/Space/Space";
import {conn} from "../../helpers/request/connect";
import Text from "../../components/Text/Text";
import {cookies} from "../../helpers";
import Button from "../components/Button/Button";

const Login = () => {
    const [info, setInfo] = useState({login: "", password: "", auth: ""});
    const [err, setErr] = useState(false);
    const [loading, setLoading] = useState(false);
    return (
        <Div column alignH={"center"} alignV={"center"} style={{height: "100vh"}}>
            <Input label={"Մուտքանուն"} placeholder={"Login"} onChange={(e)=>setInfo(prev=>{return {...prev, login: e}})} />
            <Space size={"sm"} vertical/>
            <Input label={"Գաղտնաբառ"} placeholder={"Password"} type={"password"} onChange={(e)=>setInfo(prev=>{return {...prev, password: e}})} />

            {/*<Space size={"sm"} vertical/>
            <Input label={"Google Authenticator"} placeholder={"Authenticator"} onChange={(e)=>setInfo(prev=>{return {...prev, auth: e}})} value={info.auth} />
*/}
            {err? <>
                <Space size={"sm"}/>
             <Text color={"red"}>Wrong login or password</Text>
            </> : <></>}
            <Space size={"sm"}/>
            <Button title={"Sing in"} loading={loading} stretch={"fixed"} onClick={()=>{
                if(info.login.length > 0 && info.password.length > 4){
                    setErr(false);
                    setLoading(true);
                    conn("sign", info).then((res: any)=>{
                        if(res.token && res.id){
                        cookies.set("ad_id", res.id);
                        cookies.set("ad_token", res.token);
                        window.location.reload();
                        }else{
                            setErr(true);
                        }
                        setLoading(false);
                    });
                }else{
                    setErr(true);
                }

            }}/>
        </Div>
    );
};

export default Login;
