import React, {useEffect, useMemo, useState} from 'react';
import Div from "../components/Div/Div";
import Text from "../components/Text/Text";
import Space from "../components/Space/Space";
import Button from "./components/Button/Button";
import ListItem from "../components/ListItem/ListItem";
import {conn, uploadFile} from "../helpers/request/connect";
import Dialog from "./components/Dialog/Dialog";
import Input from "../components/Input/Input";
import Preloader from "./components/Preloader/Preloader";
import LangTabs from "./components/LangTabs/LangTabs";
import {Select} from "./components/Select/Select";
import {CiCirclePlus} from "react-icons/ci";
import ProductEditPage from "./ProductEditPage";
import {getImage} from "../helpers";
import Uploader from "./components/Uploader/Uploader";

const ProductCategory = () => {

    const [items, setItems] = useState([]);
    const [info, setInfo] = useState<any>({});
    const [editContext, setEditContext] = useState(false);
    const [remove, setRemove] = useState(null);
    const [loading, setLoading] = useState(true);
    const [products, setProducts] = useState(0);

    useEffect(()=>{
        if(editContext && info.id){
            setProducts(0);
            conn("products/getCateProductsCount", {id: info.id}).then((res:any)=>{
                setProducts(res.response);
            });
        }
    }, [editContext, info.id])

    useEffect(() => {
        conn("products/getCategories").then((res: any) => {
            if (res.response) setItems(res?.response?.sort((a,b)=>a.sort - b.sort));
            setLoading(false);
        });
    }, []);

    const saveDoc = (data) => {
        setLoading(true);
        conn("products/saveCategory", data).then(() => {
             window.location.reload();
        });
    }

    const uploadFileFunc = async (file, type) => {
        if (typeof file === "string") return file;
        else {
            const res: any = await uploadFile(file, type, false);
            if (res.response) return res.response;
            else {
                alert("File upload error");
                setLoading(false);
            }
        }
    }


    return (
        <Div column>
            <Preloader active={loading}/>
            <Space/>
            <Text size={"lm"} color={"main"}>Product categories</Text>
            <Space/>
            <Button onClick={() => {
                setEditContext(true);
                setInfo({sort: Number(items?.[items?.length - 1]?.sort) + 1});
            }} stretch={"fixed"} title={"Add new"} color={"green"}/>
            <Space/>
            <Div column>
                {items.map(item => {
                    return <ListItem
                        key={"slide-" + item.id}
                        hover
                        onClick={() => {
                            setInfo(item);
                            setEditContext(true);
                        }}
                        title={<Text>{item.sort} {item.title_hy}
                        </Text>}/>
                })}
            </Div>

            {/*///////////////////// edit /////////////////////*/}

            {editContext && <Dialog
                title={info?.id? info.title_hy :"Product category"}
                onClose={() => {
                    setInfo({});
                    setEditContext(false);
                }}
            >
                <Space/>
                <Uploader
                    value={info?.image ? (typeof info?.image === "string" ? getImage(info?.image) : URL.createObjectURL(info.image)) : undefined}
                    onChange={(e) => setInfo(prev => {
                        return {...prev, image: e}
                    })}
                    size={"ml"}
                />
                <Space/>
                <Input type={"number"} label={"Sort"} placeholder={"Sort"} value={info.sort ?? "1"}
                       onChange={(e) => setInfo(prev => {
                           return {...prev, sort: e}
                       })}/>
                <Space/>
                <LangTabs tabs={[

                    {
                        title: "Հայերեն",
                        content: <Div column>
                            <Input stretch={"full"} label={"Անվանում"} placeholder={"Անվանում"} value={info.title_hy}
                                   onChange={(e) => setInfo(prev => {
                                       return {...prev, title_hy: e}
                                   })}/>
                        </Div>
                    },
                    {
                        title: "English",
                        content: <Div column>
                            <Input stretch={"full"} label={"Title"} placeholder={"Title"} value={info.title_en}
                                   onChange={(e) => setInfo(prev => {
                                       return {...prev, title_en: e}
                                   })}/>
                        </Div>
                    },
                    {
                        title: "Русский",
                        content: <Div column>
                            <Input stretch={"full"} label={"Название"} placeholder={"Название "}
                                   value={info.title_ru} onChange={(e) => setInfo(prev => {
                                return {...prev, title_ru: e}
                            })}/>
                        </Div>
                    }
                ]}/>
                <Space/>
                {products > 0 && info.id? <><Text singleLine align={"right"} color={"red"} size={"sm"}>Remove all products from category to delete category</Text><Space size={"sm"}/></>: null}
                <Div gap={12} alignV={"end"}>
                    <Button stretch={"full"} title={"Cancel"} onClick={() => {
                        setInfo({});
                        setEditContext(false);
                    }} color={"grey"}/>
                    <Button stretch={"full"} title={"Save"} onClick={async () => {
                        if (!info.title_hy?.length) {
                            alert("Fill in required fields");
                        } else {
                            const img = (info.image && typeof info.image !== "string")? await uploadFileFunc(info.image, "simple") : (info.image ?? "");
                            saveDoc({...info, image: img});
                        }
                    }} color={"green"}/>
                    {info.id ? <Button disabled={products > 0} stretch={"full"} title={"Delete"} onClick={() => setRemove(info.id)} color={"red"}/> : null}
                </Div>
            </Dialog>}

            {/*///////////////////// remove /////////////////////*/}

            {remove && <Dialog
                size={"xsm"}
                title={"Delete"}
                onClose={() => setRemove(null)}
                buttons={{
                    right: <Div>
                        <Button title={"Cancel"} color={"transparent"} onClick={() => setRemove(null)}/>
                        <Button disabled={products > 0} title={"Delete"} color={"red"} onClick={() => {
                            conn("products/removeCategory", {id: info.id}).then(() => window.location.reload())
                        }}/>
                    </Div>
                }}
            >
                <Text>Do you want to delete this Product category?</Text>
            </Dialog>}
        </Div>
    );
};

export default ProductCategory;
