import React, {useEffect, useState} from 'react';
import "./DisciplinePage.scss";
import Div from "../../components/Div/Div";
import MainSlider from "../../components/MainSlider/MainSlider";
import Space from "../../components/Space/Space";
import PageTitle from "../../components/PageTitle/PageTitle";
import {lang, lg} from "../../helpers/lang/lang";
import Text from "../../components/Text/Text";
import GradientBG from "../../components/GradientBG/GradientBG";
import {Link} from "react-router-dom";
import ProductItem from "../../components/ProductItem/ProductItem";
import PlusButton from "../../components/PlusButton/PlusButton";
import SimpleScroller from "../../components/SimpleScroller/SimpleScroller";
import {BsPlayCircle} from "react-icons/bs";
import Preloader from "../../admin/components/Preloader/Preloader";
import {conn, uploads_url} from "../../helpers/request/connect";
import {useParams} from "react-router";
import {getImage, getVideoCover, langChoose} from "../../helpers";
import VideoPlayer from "../../components/VideoPlayer/VideoPlayer";


const DisciplinePage = () => {

    const params = useParams();
    const [loading, setLoading] = useState(true);
    const [discipline, setDiscipline] = useState<any>({});
    const [page, setPage] = useState(null);
    const [catalogLoading, setCatalogLoading] = useState(true);
    const [catalogs, setCatalogs] = useState([]);
    const [catalogCount, setCatalogCount] = useState(0);
    const [products, setProducts] = useState([]);
    const [productCount, setProductCount] = useState(0);
    const [productPage, setProductPage] = useState(null);
    const [productLoading, setProductLoading] = useState(true);
    const [videos, setVideos] = useState([]);
    const [selectedVideo, setSelectedVideo] = useState(null);


    useEffect(()=>{
       if(page) conn("web/getCatalogs", {discipline_id: params.p2, page: page}).then(((res:any)=>{
            setCatalogs(page === 1? res.response : (prev=>[...prev, ...res.response]));
            if(res.count) setCatalogCount(res.count);
            setCatalogLoading(false);
        }));
    }, [page, params.p2]);

    useEffect(()=>{
       if(page) conn("web/getProducts", {discipline_id: params.p2, page: productPage}).then(((res:any)=>{
            setProducts(productPage === 1? res.response : (prev=>[...prev, ...res.response]));
            if(res.count) setProductCount(res.count);
             setProductLoading(false);
        }));
    }, [productPage, params.p2]);

    useEffect(()=>{
        if(params.p2){
            setLoading(true);
            conn("web/getDiscipline", {id: params.p2}).then((res:any)=>{
                if(res.response) setDiscipline(res.response);
                setLoading(false)
            });

            conn("web/getVideos", {discipline_id: params.p2, limit: 999}).then(((res:any)=>{
                setVideos(res.response);
            }));
            setPage(null);
           setTimeout(()=> {
               setPage(1);
               setProductPage(1);
           }, 100);
        }else window.location.href = "/";
    }, [params?.p2])


    return (
        <Div column className={"discipline"}>
            <Preloader active={loading}/>
            <MainSlider slides={[{text1: langChoose(discipline, "title_", lg), image: getImage(discipline?.image),}]}/>
            <Div className={"width"} column>
                <Space size={"xlg"}/>
                <Text className={"catalogs-sub-text"} color={"grey-light"} size={"lm"}
                      uppercase={true}>{langChoose(discipline, "text_", lg)}</Text>
                {products.length ? <>
                    <Space size={"lg"}/>
                    <PageTitle upperCase={true}>{lang["products"]}</PageTitle>
                    <GradientBG><>
                        <Div column width={"full"}>
                            <Div wrap={true} className={"product-items-content"}>
                                {products?.map(pr => {
                                    return <Link to={"/" +lg+"/products/" + pr.id}
                                                 key={"pr-item" + pr.id}><ProductItem
                                        product={{...pr}}/></Link>
                                })}
                            </Div>
                        </Div>
                        {productCount > products?.length ? <>
                            <Space size={"xmd"}/>
                            <Div width={"full"} alignV={"center"}>
                                <PlusButton
                                    loading={productLoading}
                                    title={"See more..."}
                                    onCLick={() => {
                                        setProductLoading(true);
                                        setProductPage(prev => prev + 1);
                                    }}
                                /></Div>
                        </> : null}
                    </>
                    </GradientBG>
                </> : null}
                {catalogs.length ? <>
                    <Space size={"lg"}/>
                    <PageTitle upperCase={true}>{lang["catalogs"]}</PageTitle>
                    <GradientBG><>
                        <Div column width={"full"}>
                            <Div wrap={true} className={"product-items-content"}>
                                {catalogs?.map(pr => {
                                    return <Link to={uploads_url + "/files/" + pr.file} target={"_blank"}
                                                 key={"pr-item" + pr.id}><ProductItem
                                        product={{...pr, download: true}}/></Link>
                                })}
                            </Div>

                        </Div>
                        {catalogCount > catalogs?.length ? <>
                            <Space size={"xmd"}/>
                            <Div width={"full"} alignV={"center"}>
                                <PlusButton
                                    loading={catalogLoading}
                                    title={"See more..."}
                                    onCLick={() => {
                                        setCatalogLoading(true);
                                        setPage(prev => prev + 1);
                                    }}
                                /></Div>
                        </> : null}

                    </>
                    </GradientBG>
                </>: null}

            </Div>
            {videos?.length ? <Div column className={"discipline-video-scroller"}>
                <Div column className={"width"}>
                    <PageTitle>{lang["videos"]}</PageTitle>
                    <Space line/>
                </Div>
                <Space/>
                <SimpleScroller items={videos?.map(video => {
                    return <Div onClick={() => setSelectedVideo(video)} key={"media-scroller" + video.id}
                                className={"video-scroller-item"}>
                        <img src={getVideoCover(video)}/>
                        <BsPlayCircle/>
                    </Div>
                })}/>
                <Space/>
            </Div> : null}
            {selectedVideo? <VideoPlayer video={selectedVideo} onClose={()=>setSelectedVideo(null)}/> : null}
        </Div>
    );
};

export default DisciplinePage;
