import React from 'react';
import "./Button.scss";
const Button = (props: {title: string, onClick?: ()=>void}) => {
    const {title, onClick} = props;
    return (
        <div className={"button-w"} onClick={onClick}>{title}</div>
    );
};

export default Button;
