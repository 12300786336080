import React, {useEffect, useRef, useState} from 'react';

import "./Editor.scss";
import {isValidUrl, getEditorState} from "./editorHelpers";
import {GrTextAlignCenter, GrTextAlignFull, GrTextAlignLeft, GrTextAlignRight} from "react-icons/gr";

import {ColorPicker, FontSIzePicker} from "./ToolbarButtons";
import Dialog from "../Dialog/Dialog";
import Button from "../Button/Button";
import {LuLink2, LuLink2Off} from "react-icons/lu";
import MediaPicker from "./MediaPicker";
import {FiImage} from "react-icons/fi";
import {TfiLayoutMediaLeft, TfiLayoutMediaLeftAlt, TfiLayoutMediaRight} from "react-icons/tfi";
import classNames from "classnames";
import {AiOutlineFullscreenExit} from "react-icons/ai";
import {BsArrowsFullscreen, BsYoutube} from "react-icons/bs";
import {RiSuperscript2} from "react-icons/ri";
import Div from "../../../components/Div/Div";
import Space from "../../../components/Space/Space";
import Input from "../../../components/Input/Input";
import {uploads_url} from "../../../helpers/request/connect";
import Text from "../../../components/Text/Text";

const Editor = (props: {
    value?: string,
    onChange: (e) => void,
    toolbar?: boolean,
    size?: "sm" | "md"
}) => {

    const {value, onChange, toolbar = true, size = "md"} = props;

    const inputRef: any = useRef();
    const [editorState, setEditorState] = useState<any>({})
    const [selectedLink, setSelectedLink] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [imagePicker, setImagePicker] = useState(null);
    const [imageAttr, setImageAttr] = useState(null);
    const [fullscreen, setFullscreen] = useState(false);
    const [videoPicker, setVideoPicker] = useState(false);
    const [videoUrl, setVideoUrl] = useState("");



    const checkEditorItems = (e) => {
        const a = e.target.closest("a");
        setSelectedLink(a);
    }

    const checkEditorItemsDouble = (e)=>{
        const content = e.target.closest(".editor-img-content");
        if(content){
            const img = content.querySelector("img");
            setSelectedImage(content);
            setImageAttr({width: img.getAttribute("width") ?? "", float: content.getAttribute("float"), align: content.getAttribute("align")});
        }else if (!e.target.closest(".image-editor")) {
            setSelectedImage(null);
        }
    }

    const [observer, setObserver] = useState(null);

    const mutation = ()=>{
      onChange(inputRef?.current?.querySelector(".editor-in")?.[toolbar? "innerHTML": "innerText"]);
    }

    useEffect(() => {
        if (inputRef?.current?.querySelector(".editor-in")) {
            setObserver(new MutationObserver(mutation).observe(inputRef?.current?.querySelector(".editor-in"), {
                childList: true,
                subtree: true,
                characterData: true,
                attributes: true,
            }));
        }
        return () => {
            if (observer) observer.disconnect();
        }
    }, [inputRef?.current?.querySelector(".editor-in")]);


    useEffect(() => {
        inputRef?.current?.addEventListener("click", checkEditorItems);
        inputRef?.current?.addEventListener("dblclick", checkEditorItemsDouble);

        return () => {
            inputRef?.current?.removeEventListener("click", checkEditorItems);
            inputRef?.current?.removeEventListener("dblclick", checkEditorItemsDouble);
        }

    }, [inputRef]);


    useEffect(() => {
        const innerHtml = inputRef.current.querySelector(".editor-in").innerHTML;

        if(value !== innerHtml) {
          inputRef.current.querySelector(".editor-in").innerHTML = value ?? "";
      }
    }, [value]);

    const [urlContext, setUrlContext] = useState(null);
    const [urlInput, setUrlInput] = useState("");

    const [editorRange, setEditorRange] = useState<any>(null);

    const editorClasses = classNames("Editor", {full: fullscreen, [`size-${size}`]: true});

    return (
        <div className={editorClasses}>
            {toolbar && <Div className={"editor-toolbar"} gap={4}>
                <div className={`toolbar-button bold-button${editorState.bold ? " hovered" : ""}`} onMouseDown={(e) => {
                    e.preventDefault();
                    document.execCommand("bold", true, '');
                    setEditorState(getEditorState());
                    inputRef.current.querySelector(".editor-in")?.focus();
                    return false;
                }}>B
                </div>
                <div className={`toolbar-button italic-button${editorState.italic ? " hovered" : ""}`}
                     onMouseDown={(e) => {
                         e.preventDefault();
                         document.execCommand("italic", true, '');
                         setEditorState(getEditorState());
                         inputRef.current.querySelector(".editor-in")?.focus();
                         return false;
                     }}>I
                </div>
                <div className={`toolbar-button underline-button${editorState.underline ? " hovered" : ""}`}
                     onMouseDown={(e) => {
                         e.preventDefault();
                         document.execCommand("underline", true, '');
                         setEditorState(getEditorState());
                         inputRef.current.querySelector(".editor-in")?.focus();
                         return false;
                     }}>U
                </div>
                <Space size={"xsm"} vertical={false}/>

                <div className={`toolbar-button${editorState.justifyLeft ? " hovered" : ""}`} onMouseDown={(e) => {
                    e.preventDefault();
                    document.execCommand("justifyLeft", false, '');
                    setEditorState(getEditorState());
                    inputRef.current.querySelector(".editor-in")?.focus();
                    return false;
                }}><GrTextAlignLeft/></div>

                <div className={`toolbar-button${editorState.justifyCenter ? " hovered" : ""}`} onMouseDown={(e) => {
                    e.preventDefault();
                    document.execCommand("justifyCenter", false, '');
                    setEditorState(getEditorState());
                    inputRef.current.querySelector(".editor-in")?.focus();
                    return false;
                }}><GrTextAlignCenter/></div>

                <div className={`toolbar-button${editorState.justifyFull ? " hovered" : ""}`} onMouseDown={(e) => {
                    e.preventDefault();
                    document.execCommand("justifyFull", false, '');
                    setEditorState(getEditorState());
                    inputRef.current.querySelector(".editor-in")?.focus();
                    return false;
                }}><GrTextAlignFull/></div>


                <div className={`toolbar-button${editorState.justifyRight ? " hovered" : ""}`} onMouseDown={(e) => {
                    e.preventDefault();
                    document.execCommand("justifyRight", false, '');
                    setEditorState(getEditorState());
                    inputRef.current.querySelector(".editor-in")?.focus();
                    return false;
                }}><GrTextAlignRight/></div>

                <div className={`toolbar-button${editorState.superscript ? " hovered" : ""}`} onMouseDown={(e) => {
                    e.preventDefault();
                    document.execCommand("superscript", false, '');
                    setEditorState(getEditorState());
                    inputRef.current.querySelector(".editor-in")?.focus();
                    return false;
                }}><RiSuperscript2/></div>

                <Space size={"xsm"} vertical={false}/>

                <ColorPicker value={editorState.fontColor} onChange={(color) => {
                    document.execCommand("foreColor", false, color);
                    setEditorState(getEditorState());
                    inputRef.current.querySelector(".editor-in")?.focus();
                    return false;
                }}/>

                <FontSIzePicker onChange={(size) => {
                    document.execCommand("fontSize", false, size);
                    setEditorState(getEditorState());
                    inputRef.current.querySelector(".editor-in")?.focus();
                    return false;
                }} value={editorState.fontSize}/>

                <div className={`toolbar-button`} onMouseDown={(e) => {
                    e.preventDefault();
                    if (selectedLink) {
                        selectedLink.replaceWith(selectedLink.innerText);
                        setSelectedLink(null);
                    } else {
                        const selection = document.getSelection();
                        const range = selection.getRangeAt(0);
                        setEditorRange({selection: selection, range: range});
                        setUrlContext(true);
                    }
                    return false;
                }}>{selectedLink ? <LuLink2Off/> : <LuLink2/>}</div>

                {urlContext && <Dialog
                    title={"Link"}
                    size={"xsm"}
                    onClose={() => {
                        editorRange?.selection.removeAllRanges();
                        editorRange?.selection.addRange(editorRange?.range)
                        setUrlInput("");
                        setUrlContext(null);
                    }}
                >
                    <Div column gap={12} alignH={"center"}>
                        <Input value={urlInput} placeholder={"URL"} onChange={setUrlInput}/>
                        <Button
                            stretch={"fixed"}
                            title={"ok"}
                            onMouseDown={() => {
                                if (isValidUrl(urlInput)) {
                                    editorRange?.selection.removeAllRanges();
                                    editorRange?.selection.addRange(editorRange?.range);
                                    const selection = document.getSelection();
                                    document.execCommand("createLink", false, urlInput);
                                    // @ts-ignore
                                    selection.anchorNode.parentElement.target = '_blank';
                                    setUrlContext(null);
                                    setUrlInput("");
                                } else {
                                    alert("Insert valid url!")
                                }
                            }}/>
                    </Div>
                </Dialog>}

                {videoPicker && <Dialog
                    title={"Link"}
                    size={"xsm"}
                    onClose={() => {
                        editorRange?.selection.removeAllRanges();
                        editorRange?.selection.addRange(editorRange?.range)
                        setVideoPicker(false);
                        setVideoUrl("");
                    }}
                >
                    <Div column gap={12} alignH={"center"}>
                        <Input value={videoUrl} placeholder={"URL"} onChange={setVideoUrl}/>
                        <Button
                            stretch={"fixed"}
                            title={"ok"}
                            onMouseDown={() => {
                                if (videoUrl?.length) {
                                    editorRange?.selection.removeAllRanges();
                                    editorRange?.selection.addRange(editorRange?.range);
                                    document.execCommand("insertHtml", false, `<iframe class="youtube-player" width="560" height="315" src="https://www.youtube.com/embed/${videoUrl}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`);
                                    setVideoPicker(false);
                                    setVideoUrl("");
                                } else {
                                    alert("Insert valid video url!")
                                }
                            }}/>
                    </Div>
                </Dialog>}

                <div className={`toolbar-button`} onMouseDown={(e) => {
                    e.preventDefault();
                    inputRef.current.querySelector(".editor-in").focus();
                    console.log(inputRef.current);
                    try{
                        const selection = window.getSelection();
                        if(selection){
                            const range = selection?.getRangeAt(0);
                            setEditorRange({selection: selection, range: range});
                            setImagePicker(true);
                        }
                    }catch (e){
                        const selection = window.getSelection();
                        if(selection){
                            const range = document.createRange();
                            setEditorRange({selection: selection, range: range});
                            setImagePicker(true);
                    }}
                    return false;
                }}><FiImage/></div>

                <div className={`toolbar-button`}
                     onMouseDown={(e) => {
                         e.preventDefault();
                         inputRef.current.querySelector(".editor-in").focus();
                         const selection = document.getSelection();
                         const range = selection.getRangeAt(0);
                         setEditorRange({selection: selection, range: range});
                         setVideoPicker(true)
                         return false;
                     }}><BsYoutube/>
                </div>

                <div className={`toolbar-button fullscreen-button`} onMouseDown={(e) => {
                    e.preventDefault();
                    setFullscreen(!fullscreen);
                    return false;
                }}>{fullscreen ? <AiOutlineFullscreenExit/> : <BsArrowsFullscreen/>}</div>

                {imagePicker && <MediaPicker onSelect={(e) => {

                    if (e.length) {
                        editorRange?.selection.removeAllRanges();
                        editorRange?.selection.addRange(editorRange?.range);
                        document.execCommand("insertHTML", false, `<div class="editor-img-content" contenteditable="false"> <div class="editor-img-content-in" ><img src="${uploads_url + "/media/" + e}"/> </div></div>`);
                    }
                    setImagePicker(false);
                }} onClose={() => setImagePicker(false)}/>}
            </Div> || null}
            <div ref={inputRef} className={"editor-content"}>
                <div
                    onInput={(e) => {
                        // @ts-ignore
                        onChange(e.target.innerHTML);
                    }}
                    className={"editor-in"}
                    onPaste={(e: any) => {
                        e.preventDefault();
                        const text = (e.originalEvent || e).clipboardData.getData('text/plain');
                        document.execCommand("insertText", false, text);
                    }}
                    onClick={() => setEditorState(getEditorState())}
                    onKeyUp={() => setEditorState(getEditorState())}
                    contentEditable={true}

                ></div>
                {selectedLink && <div className={"editor-link-preview"}>{selectedLink?.getAttribute("href")}</div>}

                {selectedImage && <Dialog
                    title={"Image options"}
                    size={"xsm"}
                    closeOnCLickOut={false}
                    buttons={{
                        right: <Div gap={12}>
                            <Button title={"Apply"} color={"green"} onClick={() => {
                                selectedImage.querySelector("img").setAttribute("width", imageAttr.width);
                                selectedImage.setAttribute("float", imageAttr.float);
                                selectedImage.setAttribute("align", imageAttr.align);
                                setSelectedImage(null);
                            }}/>
                            <Button title={"Remove"} color={"red"} onClick={() => {
                                selectedImage.remove();
                                setSelectedImage(null);
                            }}/>
                        </Div>
                    }}
                    onClose={() => {
                        setSelectedImage(null);
                    }}>
                    <Div className={"image-editor"} column={true}>
                        <Space/>
                            <Input stretch={"full"} label={"Width"} autofocus placeholder={"Width"} onChange={(e) => setImageAttr(prev => {
                                return {...prev, width: e}
                            })} size={"sm"} value={imageAttr.width ?? ""}/>



                        <Space size={"sm"}/>
                        <Text>Float</Text>
                        <Space size={"xsm"}/>
                        <Div gap={12}>
                            <Button size={"xsm"} iconLeft={<TfiLayoutMediaLeftAlt/>}
                                    color={imageAttr.float !== "left" && imageAttr.float !== "right" ? "green" : "transparent"}
                                    onClick={() => setImageAttr(prev => {
                                        return {...prev, float: "none"}
                                    })}/>
                            <Button size={"xsm"} iconLeft={<TfiLayoutMediaLeft/>}
                                    color={imageAttr.float === "left" ? "green" : "transparent"}
                                    onClick={() => setImageAttr(prev => {
                                        return {...prev, float: "left", align: ""}
                                    })}/>
                            <Button size={"xsm"} iconLeft={<TfiLayoutMediaRight/>}
                                    color={imageAttr.float === "right" ? "green" : "transparent"}
                                    onClick={() => setImageAttr(prev => {
                                        return {...prev, float: "right", align: ""}
                                    })}/>
                        </Div>
                        {imageAttr.float !== "left" && imageAttr.float !== "right"? <>
                        <Space size={"sm"}/>
                        <Text>Align</Text>

                            <Space size={"xsm"}/>
                            <Div gap={12}>
                                <Button size={"xsm"} iconLeft={<GrTextAlignLeft/>}
                                        color={imageAttr.align !== "center" && imageAttr.align !== "right" ? "green" : "transparent"}
                                        onClick={() => setImageAttr(prev => {
                                            return {...prev, align: ""}
                                        })}/>
                                <Button size={"xsm"} iconLeft={<GrTextAlignCenter/>}
                                        color={imageAttr.align === "center" ? "green" : "transparent"}
                                        onClick={() => setImageAttr(prev => {
                                            return {...prev, align: "center"}
                                        })}/>

                                <Button size={"xsm"} iconLeft={<GrTextAlignRight/>}
                                        color={imageAttr.align === "right" ? "green" : "transparent"}
                                        onClick={() => setImageAttr(prev => {
                                            return {...prev, align: "right"}
                                        })}/>
                            </Div>
                        </>: null}
                    </Div>
                </Dialog>}
            </div>

        </div>
    );
};

export default Editor;



