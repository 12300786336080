import React, {useEffect, useMemo, useState} from 'react';
import Div from "../Div/Div";
import "./MainSlider.scss";
import classNames from "classnames";
import {AiOutlineLeft, AiOutlineRight} from "react-icons/ai";
import Button from "../Button/Button";
import Space from "../Space/Space";
import {Link} from "react-router-dom";
import {useParams} from "react-router";
import Preloader from "../../admin/components/Preloader/Preloader";



const MainSlider = (props: {slides: any[]}) => {
    const {slides = []} = props;
    const [selected, setSelected] = useState(0);
    const [timer, setTimer] = useState<any>();


    useEffect(()=>{

       // document.querySelectorAll(".slider-control-content .slide-control-item")[selected].scrollIntoView({ behavior: 'smooth', inline: 'center', block: "nearest" });
    }, [selected])



    return (
        <Div className={"main-slider"}>
            {slides.length ? <>
                <img src={slides?.[selected]?.image}/>
                <div className="slider-bg"></div>
                <SliderTextContent data={slides?.[selected]}/>
                {slides.length > 1 ? <Div className={"slider-control-content"}>

                    <Div className={"slider-control-button"} onClick={() => {
                        setSelected(selected > 0 ? selected - 1 : slides.length - 1);
                        clearInterval(timer);
                        setTimer(setInterval(() => {
                            setSelected(prev => {
                                return prev < slides.length - 1 ? prev + 1 : 0
                            })
                        }, 2000));
                    }}><AiOutlineLeft/></Div>
                    <Div className={"slider-control-button right"} onClick={() => {
                        setSelected(selected < slides.length - 1 ? selected + 1 : 0);
                        clearInterval(timer);
                        setTimer(setInterval(() => {
                            setSelected(prev => {
                                return prev < slides.length - 1 ? prev + 1 : 0
                            })
                        }, 2000));
                    }}><AiOutlineRight/></Div>
                    <Div className={"slider-control-content-in"}>
                        {slides.map((sl, i) => {
                            return <SliderControlItem key={"slider-" + i} onClick={() => {
                                setSelected(i);
                                clearInterval(timer);
                                setTimer(setInterval(() => {
                                    setSelected(prev => {
                                        return prev < slides.length - 1 ? prev + 1 : 0
                                    })
                                }, 2000));
                            }} image={sl.image} selected={selected === i}/>
                        })}
                    </Div>
                </Div> : null}
            </> : null}
        </Div>
    );
};

const SliderControlItem = (props: {
    image: string,
    selected: boolean,
    onClick: ()=>void,
})=>{
    const {image, selected, onClick} = props;
    const classes = classNames("slide-control-item", {selected: selected})
    return <Div className={classes} onClick={onClick}>
        <img src={image}/>
    </Div>
}

const SliderTextContent = (props: {data: any})=>{

    const {text1, text2, text3, link} = props?.data;

    const params = useParams();

    const lg = useMemo(()=>{
        return params.l ? params.l : localStorage.getItem("lang") ?? "en";
        }, [params])

    return <Div className={"slider-text-content width slider-animation"} column={true}>
        {text1? <Div className={"slider-text-1"}>{text1}</Div> : null}
        {text2? <Div className={"slider-text-2"}>{text2}</Div> : null}
        {text3 ? <Div className={"slider-text-3"}>{text3}</Div> : null}
        {link? <><Space size={"sm"}/>
            <Link to={"/" + lg + link}><Button title={"More"}/></Link></>: null}
    </Div>
}

export default MainSlider;
