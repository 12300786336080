import React, {useEffect, useState} from 'react';
import Div from "../components/Div/Div";
import Text from "../components/Text/Text";
import Space from "../components/Space/Space";
import Button from "./components/Button/Button";
import ListItem from "../components/ListItem/ListItem";
import {conn, uploadFile, uploads_url} from "../helpers/request/connect";
import Dialog from "./components/Dialog/Dialog";
import Uploader from "./components/Uploader/Uploader";
import {catalogTypes, getImage, getVideoCover} from "../helpers";
import Input from "../components/Input/Input";
import Preloader from "./components/Preloader/Preloader";
import LangTabs from "./components/LangTabs/LangTabs";
import ListPicker from "./components/ListPicker/ListPicker";
import {Select} from "./components/Select/Select";
import {IoMdClose} from "react-icons/io";
import {lang} from "../helpers/lang/lang";

const Catalogs = () => {

    const [items, setItems] = useState([]);
    const [info, setInfo] = useState<any>({});
    const [editContext, setEditContext] = useState(false);
    const [remove, setRemove] = useState(null);
    const [loading, setLoading] = useState(true);
    const [topics, setTopics] = useState([]);
    const [disciplines, setDisciplines] = useState([]);
    const [videos, setVideos] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [addDialog, setAddDialog] = useState(null);
    const [addInfo, setAddInfo] = useState<any>({});
    const [categories, setCategories] = useState([]);


    const [progress, setProgress] = useState(0);
    useEffect(() => {
        conn("catalogs/get").then((res: any) => {
            if(res.response) setItems(res?.response);
        conn("topics/get").then((top:any)=>{
            if(top.response) setTopics(top.response);
        conn("disciplines/get").then((dis:any)=>{
            if(dis.response) setDisciplines(dis.response);
            conn("videos/get").then((dis:any)=>{
                if(dis.response) setVideos(dis.response);
                conn("languages/get").then((dis:any)=>{
                    if(dis.response) setLanguages(dis.response);
                    conn("products/getCategories").then((res: any) => {
                        if (res.response) setCategories(res.response);
                        setLoading(false);
                    });
                });
            });
        });
        });
        })
    }, [])

    const saveDoc = (data) => {
        conn("catalogs/save", data).then(() => {
            window.location.reload();
        });
    }


    const uploadFileFunc = async (file, type)=>{
        if(typeof file === "string") return file;
        else {
            const res: any = await uploadFile(file, type, false, (e)=>type === "file" ?setProgress(e.loaded / (e.total / 100)): 0);
            if(res.response) return res.response;
            else {
                alert("File upload error");
                setLoading(false);
            }
        }
    }

    return (
        <Div column>
            <Preloader active={loading} progress={progress}/>
            <Space/>
            <Text size={"lm"} color={"main"}>Catalogs</Text>
            <Space/>
            <Button onClick={() => setEditContext(true)} stretch={"fixed"} title={"Add new"} color={"green"}/>
            <Space/>
            <Div column>
                {items.map(item => {
                    return <ListItem
                        key={"slide-" + item.id}
                        hover
                        onClick={() => {
                            setInfo(item);
                            setEditContext(true);
                        }}
                        title={<Div gap={4}>{item.sort} {item.title_hy?.length ? item.title_hy : "Untitled"}
                        </Div>}/>
                })}
            </Div>

            {/*///////////////////// edit /////////////////////*/}

            {editContext && <Dialog
                autoFocus={false}
                size={"full"}
                title={"Catalog"}
                onClose={() => {
                    setInfo({});
                    setEditContext(false);
                }}
            >
                <Uploader
                    value={info.image ? (typeof info.image === "string" ? getImage(info.image) : URL.createObjectURL(info.image)) : undefined}
                    onChange={(e) => setInfo(prev => {
                        return {...prev, image: e}
                    })}
                    size={"l"}
                />
                <Space/>
                <Text color={"grey-light"} size={"xsm"}>Upload file</Text>
                <Space size={"xsm"}/>
                {typeof info?.file === "string"? <Div column className={"width"}>
                    <Div alignH={"center"} gap={6}>
                        <Text>{info.file}</Text> <Button color={"red"} onClick={()=>setInfo(prev=>{return{...prev, file: null}})} iconLeft={<IoMdClose/>}/>
                    </Div></Div> : <input type={"file"} onChange={(e) => setInfo(prev => {
                    return {...prev, file: e.target.files[0]}
                })}/>}
                <Space/>
                <Input type={"number"} label={"Sort"} placeholder={"Sort"} value={info.sort ?? "0"}
                       onChange={(e) => setInfo(prev => {
                           return {...prev, sort: e}
                       })}/>
                <Space/>
                <Text color={"grey-light"} size={"xsm"}>Category</Text>
                <Space size={"xsm"}/>
                <Select selected={info.category_id} onChange={(e) => setInfo(prev => {
                    return {...prev, category_id: e.id}
                })} search={true}
                        options={categories?.map(dis => {
                            return {title: dis.title_hy, id: dis.id}
                        })}/>

<Space/>
                <Text color={"grey-light"} size={"xsm"}>Type</Text>
                <Space size={"xsm"}/>
                <Select selected={info.type} onChange={(e)=>setInfo(prev=>{return{...prev, type: e.id}})} options={catalogTypes?.map(dis=>{return {title: lang[dis], id: dis}})}/>
                <Space/>

                <Text color={"grey-light"} size={"xsm"}>Language</Text>
                <Space size={"xsm"}/>
                <Select selected={info.language_id} onChange={(e)=> {
                    if(e.id === "new"){
                        setAddInfo({});
                        setAddDialog("language");
                    }else {
                        setInfo(prev => {
                            return {...prev, language_id: e.id}
                        });
                    }

                }} search={true} options={[...languages?.map(dis=>{return {title: dis.title, id: dis.id}}), {title: "Add new language", id: "new"}]}/>
                <Space/>
                <LangTabs tabs={[

                    {
                        title: "Հայերեն",
                        content: <Div column>
                            <Input stretch={"full"} label={"Անվանում"} placeholder={"Անվանում"} value={info.title_hy}
                                   onChange={(e) => setInfo(prev => {
                                       return {...prev, title_hy: e}
                                   })}/>
                        </Div>
                    },
                    {
                        title: "English",
                        content: <Div column>
                            <Input stretch={"full"} label={"Title"} placeholder={"Title"} value={info.title_en}
                                   onChange={(e) => setInfo(prev => {
                                       return {...prev, title_en: e}
                                   })}/>
                        </Div>
                    },
                    {
                        title: "Русский",
                        content: <Div column>
                            <Input stretch={"full"} label={"Название"} placeholder={"Название "}
                                   value={info.title_ru} onChange={(e) => setInfo(prev => {
                                return {...prev, title_ru: e}
                            })}/></Div>
                    }
                ]}/>

                <Space/>
                <Text color={"grey-light"} size={"xsm"}>Disciplines</Text>
                <Space size={"xsm"}/>
                <ListPicker items={disciplines} onChange={(e)=>setInfo(prev=>{
                    return {...prev, disciplines: e}
                })} value={info.disciplines}/>
                <Space/>
                <Text color={"grey-light"} size={"xsm"}>Videos</Text>
                <Space size={"xsm"}/>
                <ListPicker options={{viewImage:true}} items={videos?.map(x=>{return{...x, image: getVideoCover(x)}})} lang={"hy"} onChange={(e)=>setInfo(prev=>{
                    return {...prev, videos: e}
                })} value={info.videos}/>
                <Space/>
                <Text color={"grey-light"} size={"xsm"}>Topics</Text>
                <Space size={"xsm"}/>
                <ListPicker items={topics} lang={"hy"} onChange={(e)=>setInfo(prev=>{
                    return {...prev, topics: e}
                })} value={info.topics}/>
                <Space/>
                <Div gap={12} alignV={"end"}>
                    <Button title={"Cancel"} onClick={() => {
                        setInfo({});
                        setEditContext(false);
                    }} color={"transparent"}/>
                    <Button stretch={"fixed"} title={"Save"} onClick={() => {
                        if (!info.image) {
                            alert("Image is required");
                        }else if(!info.file){
                            alert("Select a file");
                        }else if(!info.category_id){
                            alert("Select a category");
                        }else if(!info.project_id){
                            alert("Select a project");
                        }else if(!info.title_hy?.length){
                            alert("Fill in required fields");
                        }else{
                            setLoading(true);
                            (async ()=>{
                                const img = await uploadFileFunc(info.image, "simple");
                                const file = await uploadFileFunc(info.file, "file");
                                if(img && file) saveDoc({...info, file: file, image: img});
                            })();
                        }
                    }} color={"green"}/>
                    {info.id ? <Button title={"Delete"} onClick={() => setRemove(info.id)} color={"red"}/> : null}
                </Div>
            </Dialog>}

            {/*///////////////////// remove /////////////////////*/}

            {remove && <Dialog
                size={"xsm"}
                title={"Delete"}
                onClose={() => setRemove(null)}
                buttons={{
                    right: <Div>
                        <Button title={"Cancel"} color={"transparent"} onClick={() => setRemove(null)}/>
                        <Button title={"Delete"} color={"red"} onClick={() => {
                            conn("disciplines/remove", {id: info.id}).then(() => window.location.reload())
                        }}/>
                    </Div>
                }}
            >
                <Text>Do you want to delete this?</Text>
            </Dialog>}
            {addDialog === "language" &&
                <Dialog
                    title={"New language"}
                    onClose={()=>setAddDialog(null)}
                    size={"xsm"}
                    buttons={{right: <Div gap={12}>
                            <Button title={"Cancel"} color={"grey"} onClick={()=>setAddDialog(null)}/>
                            <Button title={"Add"} stretch={"fixed"} color={"green"} onClick={()=>{
                                if(addInfo.title?.length){
                                    conn("languages/save", addInfo).then(()=>{
                                        conn("languages/get").then((res:any)=>setLanguages(res.response));
                                        setAddInfo({});
                                        setAddDialog(null);
                                    })
                                }else{
                                    alert("Fill in required fields");
                                }
                            }}/>
                        </Div>}}
                >
                    <Input type={"number"} label={"Sort"} placeholder={"Sort"} value={addInfo.sort ?? "0"}
                           onChange={(e) => setAddInfo(prev => {
                               return {...prev, sort: e}
                           })}/>
                    <Space/>
                    <Input stretch={"full"} label={"Title"} placeholder={"Title"} value={addInfo.title}
                           onChange={(e) => setAddInfo(prev => {
                               return {...prev, title: e}
                           })}/>
                </Dialog>}
        </Div>
    );
};

export default Catalogs;
