import React, {useEffect} from 'react';
import Div from "../Div/Div";
import "./PartnersScroll.scss";
import {getImage} from "../../helpers";
import Text from "../Text/Text";


const PartnersScroll = (props: {partners: any[]}) => {

    const {partners} = props;

    const scrollFunc = ()=>{
        const partnersContent = document.getElementById("partners-scroll");

        if(partnersContent){
            const partnersItems = partnersContent.querySelectorAll(".partner-item");

            if(partnersItems?.length){
                const first = partnersItems?.[0];
                // @ts-ignore
                first.style.marginLeft = "-316px";
                setTimeout(()=>{
                    partnersContent.appendChild(first);
                    // @ts-ignore
                    first.style.marginLeft = "0";
                    scrollFunc();
                }, 3000);
            }
        }


    }

    useEffect(()=>{
        scrollFunc();
    }, [partners])

    return (
        <Div className={"partners-scroll"}>
            <Div className={"partners-scroll-in"} id={"partners-scroll"}>
                {partners.map((pr, i)=>{
                    return <Div onClick={pr.link?.length? ()=>window.open(pr.link, "_blank"): undefined} className={"partner-item"} key={"partner-item-" + i}>
                        <img src={getImage(pr.image)}/>
                        {pr.title ? <Div className={"partner-item-title"}><Text size={"md"} color={"light"} singleLine>{pr.title}</Text></Div> : null}
                    </Div>
                })}
            </Div>
        </Div>
    );
};

export default PartnersScroll;
