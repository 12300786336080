import React, {useEffect, useRef, useState} from 'react';
import "./Uploader.scss";
import {BsImage} from "react-icons/bs";
import {FaUserAlt} from "react-icons/fa";
import classNames from "classnames";
import {base_url} from "../../../helpers/request/connect";


const Uploader = (props: {
    onChange?: (e: any)=>void,
    progress?: {loaded: number, total: number},
    size?: "s" | "m" | "ml" | "l",
    type?:"avatar"| "image",
    disabled?: boolean,
    value?: string,
}) => {
    const {onChange, progress, type="image", disabled, value = "", size = "m"} = props;
    const inputRef:any = useRef();
    const [bg, setBg] = useState(value? value: "");

    useEffect(()=>{
        setBg(value ?? "");
    }, [value]);

    const classes = classNames("uploader", {
        "disabled": disabled,
        [`size-${size}`]: true,
    });

    return (
        <div className={classes} onClick={()=>{
            inputRef.current.click();
        }}>
            {bg && <img src={bg}/> || (type === "image"? <BsImage/> : <FaUserAlt/> )}
            <input
                onChange={(e)=>{
                    // @ts-ignore
                    setBg(URL.createObjectURL(e.target.files[0]));
                    // @ts-ignore
                   if(onChange) onChange(e.target.files[0]);

                   inputRef.current.value = [];
                }}
            ref={inputRef}
            type={"file"}
            accept={".jpg,.JPG,.png,.PNG,.jpeg,.JPEG"}
            />
            {progress && progress.loaded < progress.total && <div className="progress-bar" style={{width: (progress.loaded / progress.total) * 100 + "%"}}></div> || null}
        </div>
    );
};

export default Uploader;
